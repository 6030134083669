export class ClientUserDetail {
    id: number;
    clientId: number;
    userName: string;
    role: string;
    clientName: string;

}

export class ClientRole {
    id: number;
    clientId: number;
    name: string;
    role: string;
    inActive: boolean;
}
