import {Injectable} from '@angular/core';
import {ClientSubscription} from '../models/response/client-subscriptions.model';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ClientSubscriptionsService {

    list: ClientSubscription[];
    subscriptionData: ClientSubscription;

    constructor(private http: HttpService) {
    }

    getClientSubscriptions(clientId: string): Observable<ClientSubscription[]> {
        return this.http.get<ClientSubscription[]>(`clients/${clientId}/subscriptions`);
    }

    postClientSubscriptions(clientId: number) {
        return this.http.post(`clients/${clientId}/subscriptions`, this.subscriptionData);

    }

    putClientSubscriptions(clientId: number) {
        return this.http.put(`clients/${clientId}/subscriptions`, this.subscriptionData);
    }

    deleteClientSubscriptions(id: number): Observable<ClientSubscription> {
        return this.http.delete<ClientSubscription>(`clients/subscriptions/${id}`);
    }
}
