<div class="client-user-form-dialog" style="padding: unset;">
    <mat-toolbar class="quorum-color" matDialogTitle style="padding: unset; background-color: orangered;">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ dialogTitle }}</span>
            <button (click)="dialogRef.close()" aria-label="Close dialog" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>

        <mat-toolbar-row class="toolbar-bottom py-16" fxLayout="column" fxLayoutAlign="center center">
            <div class="contact-name mt-8">{{ clientUser.userName }}</div>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="p-24 pb-0 m-0" mat-dialog-content>

        <form [formGroup]="clientUserForm">

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>User Name</mat-label>
                    <input formControlName="name" matInput name="User Name" required>
                </mat-form-field>
            </div>


            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Role</mat-label>
                    <!-- <input name="Role" formControlName="role" matInput required> -->

                    <mat-select [(ngModel)]="clientUser.role" formControlName="role" required>
                        <mat-option *ngFor="let clientRole of clientRoles" [value]="clientRole.value">
                            {{ clientRole.value }}
                        </mat-option>
                    </mat-select>


                </mat-form-field>
            </div>


        </form>

    </div>

    <div class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center" mat-dialog-actions>

        <button
            (click)="dialogRef.close(['delete',clientUserForm])"
            *ngIf="action === 'edit'"
            aria-label="Delete"

            class="mat-focus-indicator add-product-button 
        fuse-white mt-24 mt-md-0 ng-tns-c271-134 mat-raised-button mat-button-base"
            mat-raised-button
            matTooltip="Delete">
            DELETE
        </button>

        <button
            (click)="dialogRef.close(['new',clientUserForm])"
            *ngIf="action !== 'edit'"
            [disabled]="clientUserForm.invalid"

            aria-label="SAVE"
            class="mat-focus-indicator add-product-button 
        fuse-white mt-24 mt-md-0 ng-tns-c271-134 mat-raised-button mat-button-base"
            mat-raised-button>
            ADD
        </button>

        <button
            (click)="dialogRef.close(['save',clientUserForm])"
            *ngIf="action === 'edit'"
            [disabled]="clientUserForm.invalid"

            aria-label="SAVE"
            class="mat-focus-indicator add-product-button 
        fuse-white mt-24 mt-md-0 ng-tns-c271-134 mat-raised-button mat-button-base"
            mat-raised-button>
            SAVE
        </button>

    </div>
</div>
