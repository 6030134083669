import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MsalGuard} from '@azure/msal-angular';
import {DashboardComponent} from 'app/components/pages/dashboard/dashboard.component';
import {AlertReportComponent} from 'app/components/pages/alert-report/alert-report.component';
import {VulnerablityReportComponent} from 'app/components/pages/vulnerablity-report/vulnerablity-report.component';
import {AzureModuleReportComponent} from 'app/components/pages/azure-module-report/azure-module-report.component';
import {
    AzureAlertReportComponent
} from 'app/components/pages/azure-module-report/azure-module-alert/azure-module-alert-report.component';
import {
    AzureVirtualReportComponent
} from 'app/components/pages/azure-module-report/azure-module-virtual/azure-module-virtual-report.component';
import {ComplianceReportComponent} from 'app/components/pages/compliance-report/compliance-report.component';
import {ASCAlertDetailsComponent} from '../components/pages/asc-alert-details/asc-alert-details.component';
import {AlertDetailsComponent} from '../components/pages/alert-details/alert-details.component';
import {ClientDetailsComponent} from '../components/pages/client-management/client-details/client-details.component';
import {
    ClientUserDetailsComponent
} from '../components/pages/client-management/client-user-details/client-user-details.component';
import {
    ClientSubscriptionsComponent
} from '../components/pages/client-management/client-subscriptions/client-subscriptions.component'
import {
    M365ComplianceReportComponent
} from 'app/components/pages/m365-compliance-report/m365-compliance-report.component';
import {AdminGuard} from 'app/guards/admin-guard';

const routes: Routes = [
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [MsalGuard]
    },
    {
        path: '365-alerts',
        component: AlertReportComponent,
        canActivate: [MsalGuard]
    },
    {
        path: '365-vulnerablities',
        component: VulnerablityReportComponent,
        canActivate: [MsalGuard]
    },
    {
        path: '365-compliance',
        component: M365ComplianceReportComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'azure-dashboard',
        component: AzureModuleReportComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'azure-alerts',
        component: AzureAlertReportComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'azure-others',
        component: AzureVirtualReportComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'asc-alerts/:alertId',
        component: ASCAlertDetailsComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'alerts/:alertId/:clientId',
        component: AlertDetailsComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'clients',
        component: ClientDetailsComponent,
        canActivate: [MsalGuard, AdminGuard]
    },
    {
        path: 'clients/:clientId/users',
        component: ClientUserDetailsComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'clients/:clientId/subscriptions',
        component: ClientSubscriptionsComponent,
        canActivate: [MsalGuard]
    },
    {
        path: 'compliance',
        component: ComplianceReportComponent,
        canActivate: [MsalGuard]
    },
    {
        path: '**',
        redirectTo: 'dashboard'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: false})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
