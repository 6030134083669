import {AdminGuard} from 'app/guards/admin-guard';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule
} from '@fuse/components';
import {fuseConfig} from 'app/fuse-config';

import {
    MsalGuard,
    MsalInterceptor,
    MsalModule,
    MsalService
} from '@azure/msal-angular';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from 'app/routing/app-routing.module';
import {AppComponent} from '../app/components/app.component';
import {LayoutModule} from '../app/layout/layout.module';
import {ClientManagementModule} from '../app/components/pages/client-management/client-management.module';
import {environment} from '../environments/environment';
import {DashboardPageModule} from '../app/components/pages/dashboard/dashboard.module';
import {AlertReportPageModule} from '../app/components/pages/alert-report/alert-report.module';
import {VulnerablityReportPageModule} from '../app/components/pages/vulnerablity-report/vulnerablity-report.module';
import {AzureModuleReportPageModule} from '../app/components/pages/azure-module-report/azure-modle-report.module';
import {AzureAlertReportPageModule} from '../app/components/pages/azure-module-report/azure-module-alert/azure-module-alert-report.module';
import {AzureVirtualReportPageModule} from '../app/components/pages/azure-module-report/azure-module-virtual/azure-module-virtual-report.module';
import {ComplianceReportPageModule} from '../app/components/pages/compliance-report/compliance-report.module';
import {AlertDetailsPageModule} from '../app/components/pages/alert-details/alert-details.module';
import {ASCAlertDetailsPageModule} from '../app/components/pages/asc-alert-details/asc-alert-details.module';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatFormFieldModule} from '@angular/material/form-field';
import {M365ComplianceReportPageModule} from './components/pages/m365-compliance-report/m365-compliance-report.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        // RouterModule.forRoot(appRoutes),
        MsalModule.forRoot(environment.MSAL),
        TranslateModule.forRoot(),
        // Material moment date module
        MatMomentDateModule,
        MatTableModule,
        MatSortModule,
        MatFormFieldModule,
        // Material
        MatButtonModule,
        MatIconModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        // App modules
        LayoutModule,
        DashboardPageModule,
        AlertReportPageModule,
        VulnerablityReportPageModule,
        AlertDetailsPageModule,
        AzureModuleReportPageModule,
        AzureAlertReportPageModule,
        AzureVirtualReportPageModule,
        ASCAlertDetailsPageModule,
        ClientManagementModule,
        ComplianceReportPageModule,
        M365ComplianceReportPageModule
    ],
    providers: [
        MsalGuard,
        MsalService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        AdminGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
