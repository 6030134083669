import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

// models
import {AlertDetailsResult, AlertSearchResult} from '../models/response/alert-search-result.model';
import {AlertFilterData} from '../models/response/alert-filter-data.model';
// services
import {HttpService} from './http.service';
import {AlertUpdateModel} from '../models/request/alert-update.model';
import {ConnectWiseDetails} from '../models/connectwise/connectwise.model';
import {AlertDetails} from '../models/graph/alert-details.model';

@Injectable({providedIn: 'root'})
export class AlertService {

    public alertsDeetsO: Observable<AlertDetailsResult>;
    public alertDeets: AlertDetails;

    constructor(
        private http: HttpService) {
    }

    getAlertsByFilter(filter: AlertFilterData): Observable<AlertSearchResult> {
        return this.http.post<AlertSearchResult>('alerts', filter);
    }

    getAlertDetails(id: string, clientId: string): Observable<AlertDetailsResult> {

        this.alertsDeetsO = this.http.get<AlertDetailsResult>(`alerts/${id}/${clientId}`);

        this.alertsDeetsO.subscribe(x => {
            this.alertDeets = x.alertDetails;

        });

        return this.alertsDeetsO;
    }

    updateAlert(alert: AlertUpdateModel): Observable<AlertDetailsResult> {
        console.log("Status " + alert.status);
        return this.http.patch(`alerts/${alert.id}`, alert);

    }

    createConnectWiseTicket(alert: ConnectWiseDetails): Observable<ConnectWiseDetails> {

        return this.http.post(`alerts/${alert.alertId}/${alert.clientId}`, alert);

    }

}
