import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

// page specific components
import {VulnerablityReportComponent} from './vulnerablity-report.component';

import {NgxPowerBiModule} from 'ngx-powerbi';

// components
const components = [
    VulnerablityReportComponent

];

@NgModule({
    imports: [
        CommonModule,
        NgxPowerBiModule
    ],
    providers: [],
    declarations: [components],
    exports: components
})

export class VulnerablityReportPageModule {
}
