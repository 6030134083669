import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup} from '@angular/forms';

import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';

import {ClientDetailService} from 'app/services/client-detail.service';
import {ClientRole, ClientUserDetail} from 'app/models/response/client-user-detail.model';
import {ClientUserDialog} from 'app/components/pages/client-management/client-user-dialog/client-user-dialog';

@Component({
    selector: 'app-client-user-details',
    templateUrl: './client-user-details.component.html',
    styleUrls: ['./client-user-details.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ClientUserDetailsComponent implements OnInit {
    show: boolean = false

    clientName: string
    dialogRef: any
    clientUserDetails: ClientUserDetail []
    clientUserDetail: ClientUserDetail

    displayedColumns = ['id', 'name', 'role', 'action'];
    dataSource = new MatTableDataSource(this.clientUserDetails);

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    constructor(
        private _clientDetailService: ClientDetailService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router
    ) {

        this.getAllUsers();

    }

    ngOnInit() {

        this.dataSource.sort = this.sort;

    }

    getAllUsers(): void {

        const clientId: string = this.route.snapshot.paramMap.get('clientId');

        console.log("Client Id " + clientId);

        console.log("In getAllUsers");

        this._clientDetailService.getAllUsers(clientId).subscribe(response => {

                if (response) {

                    console.log("Response is " + response);

                    this.clientName = response[0].clientName;

                    this.clientUserDetails = response;

                    this.show = true;

                    this.dataSource = new MatTableDataSource(response);
                    this.dataSource.sort = this.sort;
                } else {
                    console.log("Error " + response);
                }
            },
            err => {
                console.log("Error");
                console.log("Error " + err);
            }
        );
    }

    addClientUser(): void {
        this.dialogRef = this.dialog.open(ClientUserDialog, {
            panelClass: 'client-user-form-dialog',
            height: '600px',
            width: '600px',
            data: {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: FormGroup = response[1];
                const clientId: number = +this.route.snapshot.paramMap.get('clientId');

                this._clientDetailService.userformData = new ClientRole();
                this._clientDetailService.userformData.name = formData.value.name;
                this._clientDetailService.userformData.role = formData.value.role;
                this._clientDetailService.userformData.clientId = clientId;

                this._clientDetailService.postClientUserDetail(clientId).subscribe(response => {

                        if (response) {
                            this.getAllUsers();

                        } else {
                            console.log("Error " + response);
                        }
                    },
                    err => {
                        console.log("Error");
                        console.log("Error " + err);
                    }
                );

            });
    }

    openDialog(input: ClientUserDetail): void {

        this.dialogRef = this.dialog.open(ClientUserDialog, {
            panelClass: 'client-user-form-dialog',
            height: '600px',
            width: '600px',
            data: {
                data: input,
                action: 'edit'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: FormGroup = response[1];

                const clientId: number = +this.route.snapshot.paramMap.get('clientId');

                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'new':
                        console.log("save");
                        break;

                    case 'save':

                        console.log("Client Id " + clientId);

                        this._clientDetailService.userformData = new ClientRole();
                        this._clientDetailService.userformData.name = formData.value.name;
                        this._clientDetailService.userformData.role = formData.value.role;
                        this._clientDetailService.userformData.clientId = clientId;

                        this._clientDetailService.putClientUserDetail(clientId).subscribe(response => {

                                if (response) {
                                    this.getAllUsers();

                                } else {
                                    console.log("Error " + response);
                                }
                            },
                            err => {
                                console.log("Error");
                                console.log("Error " + err);
                            }
                        );

                        break;
                    /**
                     * Delete
                     */
                    case 'delete':

                        console.log("delete");

                        this._clientDetailService.userformData = new ClientRole();
                        this._clientDetailService.userformData.name = formData.value.name;
                        this._clientDetailService.userformData.role = formData.value.role;
                        this._clientDetailService.userformData.clientId = clientId;
                        this._clientDetailService.userformData.inActive = true;
                        this._clientDetailService.userformData.id = formData.value.id;

                        this._clientDetailService.putClientUserDetail(clientId).subscribe(response => {

                                if (response) {

                                    this.getAllUsers();

                                } else {

                                    console.log("Error " + response);
                                }
                            },
                            err => {
                                console.log("Error");
                                console.log("Error " + err);
                            }
                        );

                        break;
                }
            });
    }

}

