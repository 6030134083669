<div class="dialog-content-wrapper">
    <mat-toolbar class="quorum-color" matDialogTitle style="background-color: orangered;">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ dialogTitle }}</span>
            <button (click)="dialogRef.close()" aria-label="Close dialog" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>

        <mat-toolbar-row class="toolbar-bottom py-16" fxLayout="column" fxLayoutAlign="center center">
            <!-- <div class="contact-name mt-8">{{client.name}}</div> -->
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="p-24 pb-0 m-0" mat-dialog-content>

        <form [formGroup]="alertForm" fxFlexFill>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Board</mat-label>
                    <input formControlName="board" matInput name="Board" required>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Status</mat-label>
                    <input formControlName="status" matInput name="Status" required>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Type</mat-label>
                    <input formControlName="type" matInput name="Type" required>
                </mat-form-field>
            </div>


            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Subtype</mat-label>
                    <input formControlName="subType" matInput name="Subtype" required>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Item</mat-label>
                    <input formControlName="item" matInput name="Item" required>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start" style="width: auto; height: auto;">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Description</mat-label>
                    <textarea formControlName="body" matInput name="Description" required></textarea>

                </mat-form-field>
            </div>


        </form>

    </div>

    <div class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center" mat-dialog-actions>

        <button
            (click)="dialogRef.close(['save',alertForm])"
            [disabled]="alertForm.invalid"
            aria-label="SAVE"
            class="mat-focus-indicator add-product-button 
        fuse-white mt-24 mt-md-0 ng-tns-c271-134 mat-raised-button mat-button-base"
            mat-raised-button>
            SAVE
        </button>

    </div>
</div>
