import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ASCAlertDetails} from '../../../../models/azure/asc-alert-details.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ASCAlertService} from '../../../../services/asc-alert.service';

import {UserService} from '../../../../services/user.service';

@Component({
    selector: 'asc-log-ticket-dialog',
    templateUrl: './asc-log-ticket-dialog.html',
})

export class AscLogTicketDialog {

    action: string;
    alertForm: FormGroup;
    alert: ASCAlertDetails;
    dialogTitle: string;

    constructor(
        private alertService: ASCAlertService,
        private route: ActivatedRoute,
        private userService: UserService,
        public dialogRef: MatDialogRef<AscLogTicketDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router,
        private _formBuilder: FormBuilder) {

        this.dialogTitle = 'Log Ticket In ConnectWise';

        var urlEndpointNoise = this.router.url;
        console.log("URL END POINT " + urlEndpointNoise);

        if (this.alertService.alertDeets) {

            var alertTitle = '\n\nAlert Title:- ' + this.alertService.alertDeets.alertDisplayName;
            var alertDescription = '\n\nAlert Description:- ' + this.alertService.alertDeets.description;
            var alertId = '\n\nAlert ID: - ' + this.alertService.alertDeets.alertId;
            var alertTime = '\n\nAlert Time:- ' + this.alertService.alertDeets.detectedTimeUtc;
            var alertStatus = '\n\nAlert Status:- ' + this.alertService.alertDeets.state;
            var invokedBy = '\n\n Ticket Logged By:- ' + this.userService.getCurrentUser().displayableId;

            var SecOpsPortalReference = '\n\nCyber One:- \n\nhttps://portal.cyber-one.com.au' + urlEndpointNoise;

            this.alertForm = new FormGroup({
                'board': new FormControl('Alerts - Cyber Security'),
                'status': new FormControl('NewAlert'),
                'type': new FormControl('Azure Security Alert'),
                'subType': new FormControl('Azure Security Center'),
                'item': new FormControl('ASC Alert'),
                'body': new FormControl('Hi, ' + alertTitle + alertDescription + alertId + alertTime + alertStatus + invokedBy + SecOpsPortalReference)
            });

        } else {

            this.alertForm = new FormGroup({
                'board': new FormControl(''),
                'status': new FormControl(''),
                'type': new FormControl(''),
                'subType': new FormControl(''),
                'item': new FormControl(''),
                'body': new FormControl('')
            });

        }

    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}