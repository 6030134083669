import {FuseNavigation} from '@fuse/types';

export const navigationAdmin: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Microsoft 365',
        type: 'group',
        children: [
            {
                id: 'overview',
                title: 'Overview',
                type: 'item',
                icon: 'insert_chart',
                url: '/dashboard',

            },
            {
                id: 'alert',
                title: 'Alerts',
                type: 'item',
                icon: 'insert_chart',
                url: '/365-alerts',

            },
            {
                id: 'vulnerablity',
                title: 'Vulnerabilities',
                type: 'item',
                icon: 'insert_chart',
                url: '/365-vulnerablities',

            }

        ]
    },
    {
        id: 'applications',
        title: 'Azure',
        type: 'group',
        children: [
            {
                id: 'azureoverview',
                title: 'Overview',
                type: 'item',
                icon: 'insert_chart',
                url: '/azure-dashboard',

            },
            {
                id: 'azurealert',
                title: 'Alerts',
                type: 'item',
                icon: 'insert_chart',
                url: '/azure-alerts',

            },
            {
                id: 'azureothers',
                title: 'Resource Information',
                type: 'item',
                icon: 'insert_chart',
                url: '/azure-others',

            }
        ]
    },

];

export const clientManagementModule: FuseNavigation = {

    id: 'applications',
    title: 'Client Management',
    type: 'group',
    children: [
        {
            id: 'clientManagement',
            title: 'Client Register',
            type: 'item',
            icon: 'account_box',
            url: '/clients',

        }
    ]

};

export const complianceModule: FuseNavigation = {
    id: 'applications',
    title: 'Compliance',
    type: 'group',
    children: [
        {
            id: '365compliance',
            title: 'M365 Compliance',
            type: 'item',
            icon: 'insert_chart',
            url: '/365-compliance'
        },
        {
            id: 'overview',
            title: 'Azure Compliance Overview',
            type: 'item',
            icon: 'insert_chart',
            url: '/compliance',

        }
    ]
};

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Microsoft 365',
        type: 'group',
        children: [
            {
                id: 'overview',
                title: 'Overview',
                type: 'item',
                icon: 'insert_chart',
                url: '/dashboard',

            },
            {
                id: 'alert',
                title: 'Alerts',
                type: 'item',
                icon: 'insert_chart',
                url: '/365-alerts',

            },
            {
                id: 'vulnerablity',
                title: 'Vulnerabilities',
                type: 'item',
                icon: 'insert_chart',
                url: '/365-vulnerablities',

            }

        ]
    },
    {
        id: 'applications',
        title: 'Azure',
        type: 'group',
        children: [
            {
                id: 'azureoverview',
                title: 'Overview',
                type: 'item',
                icon: 'insert_chart',
                url: '/azure-dashboard',

            }, {
                id: 'azurealert',
                title: 'Alerts',
                type: 'item',
                icon: 'insert_chart',
                url: '/azure-alerts',

            }, {
                id: 'azureothers',
                title: 'Resource Information',
                type: 'item',
                icon: 'insert_chart',
                url: '/azure-others',

            }
        ]
    }
];
