<!-- FUSE Splash Screen -->
<div *ngIf="!show" id="fuse-splash-screen">
    <div class="center">
        <div class="logo">
            <img src="assets/images/logos/Quorum.png" width="128">
        </div>
        <!-- Material Design Spinner -->
        <div class="spinner-wrapper">
            <div class="spinner">
                <div class="inner">
                    <div class="gap"></div>
                    <div class="left">
                        <div class="half-circle"></div>
                    </div>
                    <div class="right">
                        <div class="half-circle"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- / Material Design Spinner -->
    </div>
</div>
<!-- / FUSE Splash Screen -->


<div *ngIf="show" class="page-layout carded fullwidth inner-scroll" id="client-details">

    <!-- TOP BACKGROUND -->
    <div class="top-bg quorum-color"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">
        <!-- HEADER -->
        <div class="header quorum-color" fxLayout="row" fxLayout.gt-xs="row"
             fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center">
            <!-- APP TITLE -->
            <div class="logo mb-24 mb-sm-0"
                 fxLayout="column" fxLayoutAlign="start center">
                <span [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}"
                      class="logo-text h1">Manage Clients</span>
            </div>
            <!-- / APP TITLE -->
            <!-- ADD BUTTON -->
            <div class="logo mb-24 mb-sm-0"
                 fxLayout="column" fxLayoutAlign="end center">

                <button (click)="addClient()" class="mat-focus-indicator add-product-button 
            fuse-white mt-24 mt-md-0 ng-tns-c271-134 mat-raised-button mat-button-base"
                        mat-raised-button tabindex="0">
                    <span class="mat-button-wrapper"><span>ADD NEW CLIENT</span></span>
                    <div class="mat-ripple mat-button-ripple" matripple="">
                    </div>
                    <div class="mat-button-focus-overlay"></div>
                </button>
            </div>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">
            <div class="mat-elevation-z8">
                <table [dataSource]="dataSource" mat-table>
                    <!-- ID Column -->
                    <ng-container matColumnDef="id" style="color: white;">
                        <th *matHeaderCellDef mat-header-cell style="color: white;">ID</th>
                        <td *matCellDef="let row" mat-cell style="color: white;"> {{ row.id }}</td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <th *matHeaderCellDef mat-header-cell style="color: white;" style="color: white;"> Name</th>
                        <td *matCellDef="let row" mat-cell style="color: white;"> {{ row.name }}</td>
                    </ng-container>

                    <ng-container matColumnDef="manage-users">
                        <th *matHeaderCellDef class="color-white-text" mat-header-cell style="color: white;">Users</th>
                        <td *matCellDef="let row" class="color-white-text" mat-cell style="color: white;">
                            <a routerLink="/clients/{{row.id}}/users" style="color: white;">Manage Users</a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="manage-subscriptions">
                        <th *matHeaderCellDef class="color-white-text" mat-header-cell style="color: white;">
                            Subscriptions
                        </th>
                        <td *matCellDef="let row" class="color-white-text" mat-cell style="color: white;">
                            <a routerLink="/clients/{{row.id}}/subscriptions" style="color: white;">Manage
                                Subscriptions</a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th *matHeaderCellDef mat-header-cell style="color: white;">Edit Client</th>
                        <td *matCellDef="let row" mat-cell style="color: white;">
                            <button aria-label="" disabled mat-icon-button style="color: white;">
                                <mat-icon (click)="openDialog(row.id,row)">edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr *matHeaderRowDef="displayedColumns" mat-header-row style="color: white;"></tr>
                    <tr *matRowDef="let row; columns: displayedColumns;" mat-row style="color: white;"></tr>

                    <!-- Row shown when there is no matching data. -->
                    <tr *matNoDataRow class="mat-row">
                        <td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
                    </tr>
                </table>
            </div>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>
