<div class="dialog-content-wrapper">
    <mat-toolbar class="quorum-color" matDialogTitle style="background-color: orangered;">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ dialogTitle }}</span>
            <button (click)="dialogRef.close()" aria-label="Close dialog" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>

        <mat-toolbar-row class="toolbar-bottom py-16" fxLayout="column" fxLayoutAlign="center center">
            <div class="contact-name mt-8">{{ client.name }}</div>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="p-24 pb-0 m-0" mat-dialog-content>
        <form [formGroup]="clientForm" fxFlexFill>
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Client Name</mat-label>
                    <input formControlName="name" matInput name="Client Name" required>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Tenant Id</mat-label>
                    <input formControlName="tenantID" matInput name="Tenant Id" required>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Azure App Registration Client Id</mat-label>
                    <input formControlName="appRegClientID" matInput name="Azure App Registration Client Id" required>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Azure App Registration Secret</mat-label>
                    <input formControlName="appRegSecret" matInput name="Azure App Registration Secret" required>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>ConnectWise Company Identifier</mat-label>
                    <input formControlName="companyIdentifier_ConnectWise" matInput
                           name="ConnectWise Company Identifier" required>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Security and Compliance Domain Name</mat-label>
                    <input formControlName="securityCompliance_DomainName" matInput name="Security and Compliance"
                           required>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Autolog Connectwise Tickets</mat-label>
                    <mat-select formControlName="autoLogConnectWiseTickets" required>
                        <mat-option *ngFor="let toggleFlag of toggleFlags" [value]="toggleFlag.value">
                            {{ toggleFlag.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Sync Security and Compliance</mat-label>
                    <mat-select formControlName="syncSecurityAndCompliance" required>
                        <mat-option *ngFor="let toggleFlag of toggleFlags" [value]="toggleFlag.value">
                            {{ toggleFlag.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center" mat-dialog-actions>
        <button
            (click)="close(dialogActions.Delete)"
            *ngIf="action === 'edit'"
            aria-label="Delete"
            class="mat-focus-indicator add-product-button 
        fuse-white mt-24 mt-md-0 ng-tns-c271-134 mat-raised-button mat-button-base"
            mat-raised-button
            matTooltip="Delete">
            DELETE
        </button>

        <button
            (click)="close(dialogActions.New)"
            *ngIf="action !== 'edit'"
            [disabled]="clientForm.invalid"
            aria-label="ADD"
            class="mat-focus-indicator add-product-button 
        fuse-white mt-24 mt-md-0 ng-tns-c271-134 mat-raised-button mat-button-base"
            mat-raised-button>
            ADD
        </button>

        <button
            (click)="close(dialogActions.Save)"
            *ngIf="action === 'edit'"
            [disabled]="clientForm.invalid"
            aria-label="SAVE"
            class="mat-focus-indicator add-product-button 
        fuse-white mt-24 mt-md-0 ng-tns-c271-134 mat-raised-button mat-button-base"
            mat-raised-button>
            SAVE
        </button>
    </div>
</div>
