import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
// models
import {ASCAlertDetails} from '../../../models/azure/asc-alert-details.model';
import {AlertUpdateModel} from '../../../models/request/alert-update.model';
// services
import {ASCAlertService} from '../../../services/asc-alert.service';
import {fuseAnimations} from '@fuse/animations';
import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from 'app/services/user.service';
import {MatDialog} from '@angular/material/dialog';
import {MsalService} from '@azure/msal-angular';
import {AscLogTicketDialog} from './asc-alert-details-logticket-cw/asc-log-ticket-dialog';
import {ConnectWiseDetails} from 'app/models/connectwise/connectwise.model';

interface AlertStatus {
    value: string;
}

class Entity {

    id: string;
    name: string;
}

@Component({
    selector: 'asc-app-alert-details-page',
    templateUrl: './asc-alert-details.component.html',
    styleUrls: ['./asc-alert-details.component.scss'],
    animations: fuseAnimations
})
export class ASCAlertDetailsComponent implements OnInit {

    show: boolean = false
    dialogRef: any
    alertStatus: string;
    public ascalertDetails: ASCAlertDetails;
    updateAlertForm: FormGroup;
    alert: AlertUpdateModel;
    role: string;
    showManageAlertStatus: boolean;
    cwDetails: ConnectWiseDetails;

    apiVersion2019: boolean = false;

    constructor(
        private userService: UserService,
        private dialog: MatDialog,
        private alertService: ASCAlertService,
        private route: ActivatedRoute,
        private _formBuilder: FormBuilder,
    ) {

    }

    ngOnInit(): void {
        this.userService.getUserRole().subscribe(response => {
                if (response) {
                    this.role = response.role;

                    //this.setNavigation();

                    if (this.role == 'Admin') {

                        this.showManageAlertStatus = true;
                    }

                    if (this.role == 'Client') {
                        this.showManageAlertStatus = false;
                    }

                } else {
                    console.log("Error " + response);
                }
            },
            err => {
                console.log("Error");
                console.log("Error " + err);
            });

        this.getAlertDetails();
    }

    public getAlertDetails(): void {

        const alertId: string = this.route.snapshot.paramMap.get('alertId');

        this.alertService.getASCAlertDetails(alertId).subscribe(response => {
            if (response) {
                this.ascalertDetails = response;
                this.apiVersion2019 = this.ascalertDetails.apiVersion == "2019";

                this.alertService.alertDeets = response;

                this.updateAlertForm = this._formBuilder.group({
                    status: ['', Validators.required]
                });

                this.updateAlertForm.get('status').patchValue(this.ascalertDetails.state);
                this.show = true;
                console.log("-------------------------------------");

                console.log("CW " + this.ascalertDetails.id_Ticket_ConnectWise);
                console.log("CW " + this.ascalertDetails.status_Ticket_ConnectWise);

                console.log("-------------------------------------");

            }
        });
    }

    updateAlert(): void {

        this.show = false;

        const clientId: string = this.route.snapshot.paramMap.get('clientId');

        console.log("Update Alert " + this.updateAlertForm.getRawValue().status);

        console.log("Update Alert " + this.ascalertDetails.alertName);

        this.alert = new AlertUpdateModel();
        this.alert.id = this.ascalertDetails.alertId;
        this.alert.status = this.updateAlertForm.getRawValue().status;

        this.alert.userUpn = this.userService.getCurrentUser().displayableId;

        console.log("Alert Status " + this.alert.status);
        if (this.alert) {

            this.alertService.updateAlert(this.alert).subscribe(response => {
                if (response) {
                    //this.alertDetails = response.alertDetails;

                    this.getAlertDetails();

                }
            });

        }
    }

    LogTicket(input: ASCAlertDetails): void {

        this.dialogRef = this.dialog.open(AscLogTicketDialog, {
            panelClass: 'client-form-dialog',
            height: '600px',
            width: '600px',
            data: {
                data: input,
                action: 'ticket'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    console.log("!res");

                    return;
                }
                const actionType: string = response[0];
                const formData: FormGroup = response[1];

                this.show = false;
                this.submitForm(formData);

            });
    }

    public submitForm(formData: FormGroup): void {

        console.log("Submit Form");

        const user = this.userService.getCurrentUser();

        const clientId: string = this.route.snapshot.paramMap.get('clientId');

        console.log(user);

        console.log("clientId " + clientId);

        this.cwDetails = new ConnectWiseDetails();
        this.cwDetails.alertId = this.ascalertDetails.alertId;
        this.cwDetails.body = formData.value.body;
        this.cwDetails.clientId = clientId
        this.cwDetails.invokedBy = user.displayableId;
        this.cwDetails.ticketId = '';

        this.cwDetails.board = formData.value.board;
        this.cwDetails.status = formData.value.status;

        this.cwDetails.type = formData.value.type;
        this.cwDetails.subType = formData.value.subType;
        // Commenting for QCOV2-91 as item return from Microsoft is currently inconsistent and sometimes returns a random GUID.
        // this.cwDetails.item = formData.value.item;
        this.cwDetails.result = "";
        this.cwDetails.aSCAlert = true;

        // send request to create action
        this.alertService.createConnectWiseTicket(this.cwDetails).subscribe(result => {
            if (result) {
                console.log("Result CW " + result);

                this.getAlertDetails();

            }
        });

    }

    cleanData(o) {
        if (Object.prototype.toString.call(o) == "[object Array]") {
            for (let key = 0; key < o.length; key++) {
                this.cleanData(o[key]);
                if (Object.prototype.toString.call(o[key]) == "[object Object]") {
                    if (Object.keys(o[key]).length === 0) {
                        o.splice(key, 1);
                        key--;
                    }
                }

            }
        } else if (Object.prototype.toString.call(o) == "[object Object]") {
            for (let key in o) {
                let value = this.cleanData(o[key]);
                if (value === null) {
                    delete o[key];
                }
                if (Object.prototype.toString.call(o[key]) == "[object Object]") {
                    if (Object.keys(o[key]).length === 0) {
                        delete o[key];
                    }
                }
                if (Object.prototype.toString.call(o[key]) == "[object Array]") {
                    if (o[key].length === 0) {
                        delete o[key];
                    }
                }
            }
        }
        return o;
    }

}



