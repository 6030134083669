import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

// models
import {AlertDetailsResult} from '../models/response/alert-search-result.model';
import {ConnectWiseDetails} from '../models/connectwise/connectwise.model';

// services
import {HttpService} from './http.service';

import {ASCAlertDetails} from '../models/azure/asc-alert-details.model';
import {AlertUpdateModel} from '../models/request/alert-update.model';

@Injectable({providedIn: 'root'})
export class ASCAlertService {

    public alertDeets: ASCAlertDetails;
    public alertsDeetsO: Observable<ASCAlertDetails>;

    constructor(private http: HttpService) {
    }

    getASCAlertDetails(id: string): Observable<ASCAlertDetails> {

        return this.http.get<ASCAlertDetails>(`asc/${id}`);

    }

    updateAlert(alert: AlertUpdateModel): Observable<AlertDetailsResult> {
        console.log("Status " + alert.status);
        return this.http.patch(`asc/${alert.id}`, alert);

    }

    createConnectWiseTicket(alert: ConnectWiseDetails): Observable<ConnectWiseDetails> {

        return this.http.post(`alerts/${alert.alertId}/${alert.clientId}`, alert);

    }

}