import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

// models
import {PowerBiConfig} from '../models/powerbi/powerbi-config-model';

// services
import {HttpService} from './http.service';

@Injectable({providedIn: 'root'})
export class DashboardService {
    constructor(
        private http: HttpService) {
    }

    getPowerBIEmbedConfig(reportName: string): Observable<PowerBiConfig> {
        return this.http.get<PowerBiConfig>(`powerbi/${reportName}`);
    }

}
