import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

// page specific components
import {AzureVirtualReportComponent} from './azure-module-virtual-report.component';

import {NgxPowerBiModule} from 'ngx-powerbi';

// components
const components = [
    AzureVirtualReportComponent

];

@NgModule({
    imports: [
        CommonModule,
        NgxPowerBiModule
    ],
    providers: [],
    declarations: [components],
    exports: components
})

export class AzureVirtualReportPageModule {
}
