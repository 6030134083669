import {Component, OnInit} from '@angular/core';
import {of} from 'rxjs';
import {map} from 'rxjs/operators';
import {DashboardService} from '../../../services/dashboard.service';
import {UserService} from '../../../services/user.service';
import {ClientDetailService} from 'app/services/client-detail.service';
import {MsalService} from '@azure/msal-angular';

@Component({
    selector: 'app-compliance-report',
    templateUrl: './compliance-report.component.html',
    styleUrls: ['./compliance-report.component.scss']
})

export class ComplianceReportComponent implements OnInit {
    show: boolean = false
    error: boolean = false
    complianceDisabled: boolean = true;

    public title = 'Dashboard';
    public tokenType = 'Embed';
    public screenHeight: number;
    //For Power Bi Embed
    public accessToken: string;
    public embedUrl: string;
    public id: string;

    constructor(
        private dashboardService: DashboardService,
        private userService: UserService,
        private clientDetailService: ClientDetailService,
        private authService: MsalService
    ) {
    }

    ngOnInit(): void {
        this.screenHeight = (window.innerHeight);
        this.getPowerBIEmbedConfig();
    }

    getPowerBIEmbedConfig(): void {
        this.userService.getUserRole().pipe(map(userRole => {
            if (userRole.role == 'Admin') {
                return of(true);
            } else {
                return this.clientDetailService.getClient(this.authService.clientId)
                    .pipe(map(client => client.syncSecurityAndCompliance == 'Enabled'));
            }
        })).subscribe(shouldSync => {
            if (shouldSync) {
                this.dashboardService.getPowerBIEmbedConfig('ComplianceReport')
                    .subscribe(response => {
                            if (response) {
                                this.show = true;
                                this.error = false;
                                this.accessToken = response.accessToken;
                                this.embedUrl = response.embedUrl;
                                this.complianceDisabled = false;
                                this.id = response.id;
                            } else {
                                this.show = true;
                                this.error = true;
                                this.complianceDisabled = false;
                                console.log("Error " + response);
                            }
                        },
                        err => {
                            console.log("Error");
                            console.log("Error " + err);
                        }
                    );
            } else {
                this.show = true;
                this.error = false;
                this.complianceDisabled = true;
            }
        });
    }
}
