import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxPowerBiModule} from 'ngx-powerbi';
import {M365ComplianceReportComponent} from './m365-compliance-report.component';

const components = [
    M365ComplianceReportComponent
];

@NgModule({
    declarations: [components],
    imports: [
        CommonModule,
        NgxPowerBiModule
    ],
    providers: [],
    exports: components
})

export class M365ComplianceReportPageModule {
}
