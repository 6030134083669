<!-- FUSE Splash Screen -->
<div *ngIf="!show" id="fuse-splash-screen">

    <div class="center">

        <div class="logo">
            <img src="assets/images/logos/Quorum.png" width="128">
        </div>

        <!-- Material Design Spinner -->
        <div class="spinner-wrapper">
            <div class="spinner">
                <div class="inner">
                    <div class="gap"></div>
                    <div class="left">
                        <div class="half-circle"></div>
                    </div>
                    <div class="right">
                        <div class="half-circle"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- / Material Design Spinner -->

    </div>

</div>
<!-- / FUSE Splash Screen -->


<div *ngIf="show" class="page-layout carded fullwidth inner-scroll">


    <div class="top-bg quorum-color"></div>

    <div class="center">


        <!-- HEADER -->

        <div class="header quorum-color" fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="center center"
             fxLayoutAlign.gt-xs="space-between center">

            <div class="logo mb-24 mb-sm-0" fxLayout="row" fxLayoutAlign="start start">

                <div>

                    <span [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}"
                          class="logo-text h1">{{ ascalertDetails.alertDisplayName }}</span>


                    <span [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}" class="logo-text h1">

                        <app-severity-level
                            [level]="(apiVersion2019
                                        ? ascalertDetails.reportedSeverity
                                        : ascalertDetails.severity).toLowerCase()"
                            size="large">
                        </app-severity-level>

                    </span>

                    <span [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}"
                          class="logo-text h1">
                        {{
                            apiVersion2019
                                ? ascalertDetails.reportedSeverity
                                : ascalertDetails.severity
                        }}
                    </span>

                </div>


            </div>


        </div>

        <!-- HEADER -->


        <!-- Main Content -->


        <div class="content-card">

            <div class="page-layout carded fullwidth inner-scroll" fxFlexFill fxHide.yba-min-height fxLayout="row"
                 fxLayout.xs="column" style="margin: 20px;">


                <!-- Left Card -->

                <div class="sec1" fxFlex="80" fxFlex.xs="80" fxLayoutGap="32px"
                     style="box-sizing: border-box; margin: 20px;">
                    <div fxLayout="row">

                        <!-- Alert Main Info -->

                        <div fxFlex="80">

                            <mat-card class="card-demo mat-card">

                                <mat-card-content class="large mat-card-content">


                                    <div fxLayout="row">

                                        <div fxFlex="33">

                                            <div class="h3">
                                                Alert Name
                                            </div>

                                            <div class="h3"
                                                 style="margin-top: 5px; white-space: normal; overflow-wrap: break-word;">
                                                {{
                                                    apiVersion2019
                                                        ? ascalertDetails.alertName
                                                        : ascalertDetails.alertType
                                                }}
                                            </div>
                                        </div>

                                        <div fxFlex="33">

                                            <div class="h3">
                                                Compromised Entity
                                            </div>

                                            <div class="h3"
                                                 style="margin-top: 5px; white-space: normal; overflow-wrap: break-word;">


                                                {{ ascalertDetails.compromisedEntity ? ascalertDetails.compromisedEntity : 'No data' }}

                                            </div>


                                        </div>

                                        <div fxFlex="33">

                                            <div class="h3">
                                                Created
                                            </div>

                                            <div class="h3" style="margin-top: 5px;">
                                                {{
                                                    (apiVersion2019
                                                        ? ascalertDetails.detectedTimeUtc
                                                        : ascalertDetails.timeGeneratedUtc) | date : 'M/d/yyyy, h:mm a'
                                                }}

                                            </div>


                                        </div>


                                    </div>


                                    <div fxLayout="row" style="margin-top: 20px;">


                                        <div fxFlex="33">
                                            <div class="h3">
                                                State
                                            </div>

                                            <div
                                                *ngIf="apiVersion2019"
                                                class="h3"
                                                style="margin-top: 5px;">
                                                {{ ascalertDetails.state ? ascalertDetails.state : 'No data' }}
                                            </div>
                                            <div
                                                *ngIf="!apiVersion2019"
                                                class="h3"
                                                style="margin-top: 5px;">
                                                {{ ascalertDetails.status ? ascalertDetails.status : 'No data' }}
                                            </div>
                                        </div>

                                        <div fxFlex="33">

                                            <div class="h3">
                                                Is Incident
                                            </div>

                                            <div class="h3" style="margin-top: 5px;">


                                                {{ ascalertDetails.isIncident.toString() }}

                                            </div>
                                        </div>

                                        <div fxFlex="33">

                                            <div class="h3">
                                                Subscription Id
                                            </div>

                                            <div class="h3" style="margin-top: 5px;">
                                                {{ ascalertDetails.subscriptionId ? ascalertDetails.subscriptionId : 'No data' }}
                                            </div>
                                        </div>
                                    </div>


                                    <div
                                        *ngIf="apiVersion2019"
                                        fxLayout="row"
                                        style="margin-top: 20px;">

                                        <div fxFlex="33">

                                            <div class="h3">
                                                State Updated By
                                            </div>

                                            <div class="h3"
                                                 style="margin-top: 5px; white-space: normal; overflow-wrap: break-word;">


                                                {{ ascalertDetails.updatedBy ? ascalertDetails.updatedBy : 'No data' }}
                                            </div>
                                        </div>


                                        <div fxFlex="33">

                                            <div class="h3">
                                                Action Taken
                                            </div>

                                            <div class="h3" style="margin-top: 5px;">


                                                {{ ascalertDetails.actionTaken ? ascalertDetails.actionTaken : 'No data' }}
                                            </div>
                                        </div>

                                        <div fxFlex="33">

                                            <div class="h3">
                                                Instance Id
                                            </div>

                                            <div class="h3" style="margin-top: 5px;">

                                                {{ ascalertDetails.instanceId ? ascalertDetails.instanceId : 'No data' }}
                                            </div>
                                        </div>

                                    </div>


                                    <div fxLayout="row" style="margin-top: 20px;">


                                        <div fxFlex="33">

                                            <div class="h3">
                                                Can Be Investigated
                                            </div>

                                            <div class="h3" style="margin-top: 5px;">

                                                <div>

                                                    <div>


                                                        {{ ascalertDetails.canBeInvestigated ? ascalertDetails.canBeInvestigated : 'No data' }}

                                                    </div>

                                                </div>
                                            </div>


                                        </div>

                                        <div fxFlex="33">

                                            <div class="h3">
                                                CW Ticket Id
                                            </div>

                                            <div class="h3" style="margin-top: 5px;">


                                                {{ ascalertDetails.id_Ticket_ConnectWise ? ascalertDetails.id_Ticket_ConnectWise : 'No data' }}

                                            </div>
                                        </div>

                                        <div fxFlex="33">

                                            <div class="h3">
                                                CW Ticket Status
                                            </div>

                                            <div class="h3" style="margin-top: 5px;">

                                                {{ ascalertDetails.status_Ticket_ConnectWise ? ascalertDetails.status_Ticket_ConnectWise : 'No data' }}
                                            </div>
                                        </div>

                                    </div>

                                    <div
                                        *ngIf="!apiVersion2019"
                                        fxLayout="row"
                                        style="margin-top: 20px;">

                                        <div fxFlex="33">

                                            <div class="h3">
                                                Intent
                                            </div>

                                            <div class="h3"
                                                 style="margin-top: 5px; white-space: normal; overflow-wrap: break-word;">
                                                {{ ascalertDetails.intent ? ascalertDetails.intent : "No Data" }}
                                            </div>
                                        </div>

                                    </div>

                                    <div fxLayout="row" style="margin-top: 20px; margin-bottom: 20px;">

                                        <div fxFlex="100">

                                            <div class="h3">
                                                Description
                                            </div>

                                            <div class="h3" style="margin-top: 5px;">
                                                {{ ascalertDetails.description }}

                                                <p *ngIf="apiVersion2019"> Workspace Arm Id
                                                    : {{ ascalertDetails.workspaceArmId }}</p>

                                                <p *ngIf="apiVersion2019"> Associated Resource
                                                    : {{ ascalertDetails.associatedResource }} </p>

                                                <p *ngIf="!apiVersion2019 && ascalertDetails.alertUri">
                                                    Alert URI : <a
                                                    [attr.href]="ascalertDetails.alertUri">{{ ascalertDetails.alertUri }}</a>
                                                </p>

                                            </div>
                                        </div>

                                    </div>


                                    <!-- #region remediationSteps -->

                                    <div
                                        *ngIf="ascalertDetails.arrRemediationSteps && ascalertDetails.arrRemediationSteps.length"
                                        class="list-item"
                                        style="margin-top: 20px;">

                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>Remediation Steps</mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div>


                                                <div *ngFor="let item of ascalertDetails.arrRemediationSteps">

                                                    <div>

                                                        {{ item }}
                                                        <br>


                                                    </div>

                                                </div>


                                            </div>

                                        </mat-expansion-panel>

                                    </div>

                                    <!-- #endregion remediationSteps -->


                                    <!-- #region sExtendedProperties -->

                                    <div
                                        *ngIf="ascalertDetails.sExtendedProperties && ascalertDetails.sExtendedProperties.length"
                                        class="list-item"
                                        style="margin-top: 20px;">

                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>Extended Properties</mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div>


                                                <div *ngFor="let item of ascalertDetails.sExtendedProperties">


                                                    <div>

                                                        {{ item }}
                                                        <br>
                                                        <br>

                                                    </div>

                                                </div>


                                            </div>

                                        </mat-expansion-panel>

                                    </div>

                                    <!-- #endregion sExtendedProperties -->


                                    <!-- #region sEntities -->

                                    <div *ngIf="ascalertDetails.sEntities && ascalertDetails.sEntities.length"
                                         class="list-item"
                                         style="margin-top: 20px;">

                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>Entities</mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div>

                                                <div *ngFor="let item of ascalertDetails.sEntities">


                                                    <div [innerHTML]="item">


                                                    </div>

                                                </div>


                                            </div>

                                        </mat-expansion-panel>

                                    </div>

                                    <!-- #endregion sEntities -->


                                </mat-card-content>

                            </mat-card>


                        </div>

                        <!-- Alert Main Info -->

                        <!-- Alert Source -->

                        <div fxFlex="20">

                            <mat-card class="card-demo mat-card">
                                <mat-card-title class="mat-card-title">
                                    <div class="h3">
                                        Alert Source
                                    </div>

                                </mat-card-title>

                                <mat-card-content class="large mat-card-content" style="margin-top: 20px;">


                                    <div style="margin-top: 20px;">

                                        <div class="h3">
                                            Vendor
                                        </div>

                                        <div class="h3" style="margin-top: 5px;">
                                            <!-- {{alertDetails.vendor?.vendor || 'No Data' }} -->

                                            Microsoft


                                        </div>
                                    </div>


                                    <div style="margin-top: 20px;">

                                        <div class="h3">
                                            Provider
                                        </div>

                                        <div class="h3" style="margin-top: 5px;">
                                            <!-- {{alertDetails.vendor?.provider || 'No Data' }} -->

                                            Azure

                                        </div>
                                    </div>

                                    <!-- <div   style="margin-top: 20px;">

                                        <div class="h3">
                                            Version
                                        </div>

                                        <div class="h3" style="margin-top: 5px;">
                                            {{alertDetails.vendor?.providerVersion || 'No Data' }}
                                  
                                        </div>
                                    </div>

                                 
                                    <div   style="margin-top: 20px;">

                                        <div class="h3">
                                            SubProvider
                                        </div>

                                        <div class="h3" style="margin-top: 5px;">
                                            {{alertDetails.vendor?.subProvider || 'No Data' }}
                                  
                                        </div>
                                    </div> -->


                                </mat-card-content>

                            </mat-card>

                        </div>

                        <!-- Alert Source -->

                    </div>
                </div>

                <!-- Left Card -->

                <!-- Right Card -->


                <div class="sec3" fxFlex="20" fxFlex.xs="20"
                     style="margin-right:20px; margin-top: 20px; margin-right: 20px;">

                    <mat-card *ngIf="!ascalertDetails.id_Ticket_ConnectWise" class="card-demo mat-card">
                        <mat-card-title class="mat-card-title">
                            <div class="h3">
                                Log Ticket In ConnectWise
                            </div>

                        </mat-card-title>

                        <mat-card-content class="large mat-card-content">
                            <button (click)="LogTicket(ascalertDetails)"
                                    aria-label="Log Ticket"
                                    class="mat-focus-indicator add-product-button 
                            fuse-white mt-24 mt-md-0 ng-tns-c271-134 mat-raised-button mat-button-base"
                                    mat-raised-button

                                    style="margin-top: 5px;">
                                Log Ticket
                            </button>
                        </mat-card-content>

                    </mat-card>


                    <div *ngIf="showManageAlertStatus">

                        <mat-card class="card-demo mat-card" style="margin-top: 20px;">
                            <mat-card-title class="mat-card-title">
                                <div class="h3">
                                    Manage Alert
                                </div>
                            </mat-card-title>
                            <mat-card-content class="large mat-card-content">

                                <div fxLayout="column">

                                    <form [formGroup]="updateAlertForm" fxFlex="1 0 auto" fxLayout="column"
                                          fxLayoutAlign="start" name="form">

                                        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

                                            <mat-form-field appearance="outline" fxFlex="100">
                                                <mat-label>State</mat-label>
                                                <mat-select formControlName="status" required>
                                                    <mat-option [value]="'dismiss'">
                                                        Dismiss
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error>State is required!</mat-error>
                                            </mat-form-field>

                                        </div>


                                    </form>
                                    <div class="m-0 p-16" fxLayout="column" fxLayoutAlign="start start"
                                         mat-dialog-actions>

                                        <button
                                            (click)="updateAlert()"
                                            [disabled]="updateAlertForm.invalid"
                                            aria-label="Update Alert"
                                            class="mat-focus-indicator add-product-button 
                                fuse-white mt-24 mt-md-0 ng-tns-c271-134 mat-raised-button mat-button-base"
                                            mat-raised-button>
                                            SAVE
                                        </button>


                                    </div>
                                </div>

                            </mat-card-content>

                        </mat-card>


                    </div>


                </div>


                <!-- Right Card -->


            </div>

        </div>


    </div>

</div>