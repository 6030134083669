import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

// page specific components
import {AlertReportComponent} from './alert-report.component';

import {NgxPowerBiModule} from 'ngx-powerbi';

// components
const components = [
    AlertReportComponent

];

@NgModule({
    imports: [
        CommonModule,
        NgxPowerBiModule
    ],
    providers: [],
    declarations: [components],
    exports: components
})

export class AlertReportPageModule {
}
