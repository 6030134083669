export class ClientDetail {
    iD: string;
    name: string;
    tenantID: string;
    appRegClientID: string;
    appRegSecret: string;
    companyIdentifier_ConnectWise: string;
    inActive: boolean;
    autoLogConnectWiseTickets: string;
    syncSecurityAndCompliance: string;
    securityCompliance_DomainName: string;
}

export enum ClientDialogActions {
    Delete = 'delete',
    New = 'new',
    Save = 'save',
    ConfirmationBeforeSave = 'confirmationBeforeSave'
}

export enum ClientConfirmationDialogActions {
    Save = 'save',
    Cancel = 'cancel',
    Back = 'back'
}
