import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppRoutingModule} from 'app/routing/app-routing.module';

// components
import {SeverityLevelComponent} from './severity-level/severity-level.component';
import {UserLogoComponent} from './user-logo/user-logo.component';
// import { InvokeEmailFormComponent } from './alert-email/alert-email.component';

export const MOMENT_FORMATS = {
    parseInput: 'l LT',
    fullPickerInput: 'l LT',
    datePickerInput: 'l',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};

const components = [

    SeverityLevelComponent,
    UserLogoComponent
];

@NgModule({
    imports: [
        CommonModule,
        AppRoutingModule
    ],
    declarations: components,
    exports: components
})

export class CommonComponentsModule {
}
