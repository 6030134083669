import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

// models
import {AlertDetails} from '../../../models/graph/alert-details.model';
import {Queries} from '../../../models/response/queries.model';
import {AlertUpdateModel} from '../../../models/request/alert-update.model';

// services
import {AlertService} from '../../../services/alert.service';

import {ActionService} from '../../../services/action.service';

import {fuseAnimations} from '@fuse/animations';

import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {UserService} from 'app/services/user.service';
import {MatDialog} from '@angular/material/dialog';
import {LogTicketDialog} from './alert-details-logticket-cw/log-ticket-dialog';

import {ConnectWiseDetails} from 'app/models/connectwise/connectwise.model';

interface AlertStatus {
    value: string;
}

interface AlertFeedback {
    value: string;
}

interface AlertComment {
    value: string;
}

@Component({
    selector: 'app-alert-details-page',
    templateUrl: './alert-details.component.html',
    styleUrls: ['./alert-details.component.scss'],
    animations: fuseAnimations
})
export class AlertDetailsComponent implements OnInit {
    show: boolean = false
    dialogRef: any

    cwDetails: ConnectWiseDetails;

    alert: AlertUpdateModel;

    alertStatus: string;

    @Input() update: AlertDetails;

    public alertDetails: AlertDetails;
    public queries: Queries;
    updateAlertForm: FormGroup;

    constructor(
        private userService: UserService,
        private dialog: MatDialog,
        private splashScreenService: FuseSplashScreenService,
        private alertService: AlertService,
        private actionService: ActionService,
        private route: ActivatedRoute,
        private router: Router,
        private _formBuilder: FormBuilder
    ) {

    }

    ngOnInit(): void {
        this.getAlertDetails();

    }

    public getAlertDetails(): void {

        const alertId: string = this.route.snapshot.paramMap.get('alertId');
        const clientId: string = this.route.snapshot.paramMap.get('clientId');

        this.alertService.getAlertDetails(alertId, clientId).subscribe(response => {
            if (response) {
                this.alertDetails = response.alertDetails;

                this.updateAlertForm = this._formBuilder.group({
                    status: ['', Validators.required],
                    feedback: ['', Validators.required],
                    assignTo: ['', Validators.required],
                    comment: ['', Validators.required]
                });

                this.updateAlertForm.get('status').patchValue(this.alertDetails.status);
                this.updateAlertForm.get('feedback').patchValue(this.alertDetails.feedback);
                this.updateAlertForm.get('assignTo').patchValue(this.alertDetails.assignedTo.upn);
                this.updateAlertForm.get('comment').patchValue(this.alertDetails.comments);

                this.show = true;

                console.log("-------------------------------------");

                console.log("title " + response.alertDetails.title);
                console.log("severity " + response.alertDetails.severity);

                console.log("-------------------------------------");

                // reverse activity history
                this.alertDetails.historyStates = this.alertDetails.historyStates
                    ? this.alertDetails.historyStates.reverse()
                    : [];
                this.queries = response.queries;

            }
        });
    }

    LogTicket(input: AlertDetails): void {

        this.dialogRef = this.dialog.open(LogTicketDialog, {
            panelClass: 'client-form-dialog',
            height: '600px',
            width: '600px',
            data: {
                data: input,
                action: 'ticket'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    console.log("!res");

                    return;
                }
                const actionType: string = response[0];
                const formData: FormGroup = response[1];

                this.show = false;
                this.submitForm(formData);

            });
    }

    updateAlert(): void {

        this.show = false;

        const clientId: string = this.route.snapshot.paramMap.get('clientId');

        console.log("Update Alert " + this.updateAlertForm.getRawValue().status);

        console.log("Update Alert " + this.updateAlertForm.getRawValue().feedback);
        console.log("Update Alert " + this.updateAlertForm.getRawValue().assignTo);

        console.log("Update Alert " + this.updateAlertForm.getRawValue().comment);
        console.log("Update Alert " + this.userService.getCurrentUser().displayableId);

        console.log("Update Alert " + this.alertDetails.id);

        this.alert = new AlertUpdateModel();
        this.alert.id = this.alertDetails.id;
        this.alert.status = this.updateAlertForm.getRawValue().status;
        this.alert.feedback = this.updateAlertForm.getRawValue().feedback;

        let comment: string[] = [];
        comment.push(this.updateAlertForm.getRawValue().comment);

        this.alert.comments = comment;
        this.alert.assignedTo = this.updateAlertForm.getRawValue().assignTo;
        this.alert.sendEmail = false;
        this.alert.userUpn = this.userService.getCurrentUser().displayableId;

        console.log("Alert Status " + this.alert.status);
        if (this.alert) {

            this.alertService.updateAlert(this.alert).subscribe(response => {
                if (response) {
                    //this.alertDetails = response.alertDetails;

                    this.getAlertDetails();

                    // reverse activity history
                    this.alertDetails.historyStates = this.alertDetails.historyStates
                        ? this.alertDetails.historyStates.reverse()
                        : [];
                    this.queries = response.queries;
                }
            });

        }
    }

    updatedAlert(update) {
        console.log("Update Alert Details");
        this.alertDetails = update;

    }

    public submitForm(formData: FormGroup): void {

        console.log("Submit Form");

        const user = this.userService.getCurrentUser();

        const clientId: string = this.route.snapshot.paramMap.get('clientId');

        console.log(user);

        console.log("clientId " + clientId);

        this.cwDetails = new ConnectWiseDetails();
        this.cwDetails.title = this.alertDetails.title;
        this.cwDetails.alertId = this.alertDetails.id;
        this.cwDetails.body = formData.value.body;
        this.cwDetails.clientId = clientId
        this.cwDetails.invokedBy = user.displayableId;
        this.cwDetails.ticketId = '';

        this.cwDetails.board = formData.value.board;
        this.cwDetails.status = formData.value.status;

        this.cwDetails.type = formData.value.type;
        this.cwDetails.subType = formData.value.subType;
        // Commenting for QCOV2-91 as item return from Microsoft is currently inconsistent and sometimes returns a random GUID.
        // this.cwDetails.item = formData.value.item;
        this.cwDetails.result = "";

        // send request to create action
        this.alertService.createConnectWiseTicket(this.cwDetails).subscribe(result => {
            if (result) {
                console.log("Result CW ", result);

                this.getAlertDetails();

            }
        });

    }
}

 

