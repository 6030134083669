export class ConnectWiseDetails {

    public board: string;
    public status: string;
    public type: string;
    public subType: string;
    public item: string;

    public title: string;
    public emailTo: string;
    public subject: string;
    public body: string;
    public alertId: string;
    public clientId: string;
    public invokedBy: string;

    public ticketId: string;

    public result: string;
    public aSCAlert: boolean;

}
