import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';

import {ClientSubscription} from 'app/models/response/client-subscriptions.model';

interface ClientSubscriptionStates {
    value: string;
}

@Component({
    selector: 'client-subscription-dialog',
    templateUrl: 'client-subscription-dialog.html',
    styleUrls: ['./client-subscription-dialog.scss'],
})

export class ClientSubscriptionDialog {
    action: string;
    clientSubscriptionForm: FormGroup;
    clientSubscription: ClientSubscription;
    dialogTitle: string;
    subscriptionStates: ClientSubscriptionStates[] = [{value: 'Enabled'}, {value: 'Disabled'}]

    constructor(
        public dialogRef: MatDialogRef<ClientSubscriptionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder
    ) {
        this.action = data.action
        if (this.action === 'edit') {
            this.dialogTitle = 'Edit User';
            this.clientSubscription = data.data;
        } else {
            this.dialogTitle = 'Add New Subscription';
            this.clientSubscription = new ClientSubscription();
        }
        this.clientSubscriptionForm = this.createclientSubscriptionForm();
    }

    createclientSubscriptionForm(): FormGroup {
        return this._formBuilder.group({
            displayName: [this.clientSubscription.displayName],
            subscriptionId: [this.clientSubscription.subscriptionId],
            authorizationSource: [this.clientSubscription.authorizationSource],
            state: [this.clientSubscription.state],
            states: [this.subscriptionStates[1]]
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
