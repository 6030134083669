<div class="client-subscription-dialog" style="padding: unset;">
    <mat-toolbar class="quorum-color" matDialogTitle style="padding: unset; background-color: orangered;">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ dialogTitle }}</span>
            <button (click)="dialogRef.close()" aria-label="Close dialog" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>

        <mat-toolbar-row class="toolbar-bottom py-16" fxLayout="column" fxLayoutAlign="center center">
            <div class="contact-name mt-8">{{ clientSubscription.displayName }}</div>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="p-24 pb-0 m-0" mat-dialog-content>
        <form [formGroup]="clientSubscriptionForm">

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Display Name</mat-label>
                    <input formControlName="displayName" matInput name="Display Name" required>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Subscription ID</mat-label>
                    <input formControlName="subscriptionId" matInput name="Subscription ID" required>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Subscription State</mat-label>
                    <mat-select [(ngModel)]="clientSubscription.state" formControlName="state" required>
                        <mat-option *ngFor="let subscriptionState of subscriptionStates"
                                    [value]="subscriptionState.value">
                            {{ subscriptionState.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Authorization Source</mat-label>
                    <input formControlName="authorizationSource" matInput name="Authorization Source" required>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center" mat-dialog-actions>
        <button (click)="dialogRef.close(['new',clientSubscriptionForm])"
                *ngIf="action !== 'edit'"
                [disabled]="clientSubscriptionForm.invalid"
                aria-label="ADD"
                class="mat-focus-indicator add-product-button fuse-white mt-24 mt-md-0 ng-tns-c271-134 mat-raised-button mat-button-base"
                mat-raised-button>
            ADD
        </button>

        <button
            (click)="dialogRef.close(['save',clientSubscriptionForm])"
            *ngIf="action === 'edit'"
            [disabled]="clientSubscriptionForm.invalid"
            aria-label="SAVE"
            class="mat-focus-indicator add-product-button fuse-white mt-24 mt-md-0 ng-tns-c271-134 mat-raised-button mat-button-base"
            mat-raised-button>
            SAVE
        </button>
    </div>
</div>
