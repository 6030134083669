import {EventEmitter, Injectable, OnDestroy} from '@angular/core';
import {Observable, Subscription} from 'rxjs';

// models
import {BroadcastService, MsalService} from '@azure/msal-angular';
import {User} from 'msal';

import {UserRole} from '../models/response/user-role.model';
import {HttpService} from './http.service';

@Injectable({providedIn: 'root'})
export class UserService implements OnDestroy {
    private toggleUserPanelEvent: EventEmitter<void>;
    private loginSuccessSubscription: Subscription;
    private acquireTokenFailureSubscription: Subscription;

    constructor(
        private msal: MsalService,
        private broadcastService: BroadcastService,
        private http: HttpService
    ) {
        this.toggleUserPanelEvent = new EventEmitter<void>();
        this.loginSuccessSubscription = this.broadcastService.subscribe(
            'msal:loginSuccess',
            (payload) => {
                // clear previous user info, saved in cache
                localStorage.removeItem('alertFilters');
                localStorage.removeItem('actionFilters');
                localStorage.removeItem('subscriptionFilters');
            }
        );

        this.acquireTokenFailureSubscription = this.broadcastService.subscribe(
            'msal:acquireTokenFailure',
            (error) => {
                // clear previous user info, saved in cache
                localStorage.removeItem('alertFilters');
                localStorage.removeItem('actionFilters');
                localStorage.removeItem('subscriptionFilters');
                // if error is occur during refresh token process, login again
                this.msal.loginRedirect();
            }
        );
    }

    getCurrentUser(): User {

        return this.msal.getUser();
    }

    getUserRole(): Observable<UserRole> {
        return this.http.get<UserRole>(`clients/userRole`);
    }

    logout(): void {
        this.msal.logout();
    }

    get Events() {
        return {ToggleUserPanel: this.toggleUserPanelEvent};
    }

    toggleUserPanel(): void {
        this.toggleUserPanelEvent.emit();
    }

    ngOnDestroy() {
        this.broadcastService.getMSALSubject().next(1);
        if (this.loginSuccessSubscription) {
            this.loginSuccessSubscription.unsubscribe();
        }
        if (this.acquireTokenFailureSubscription) {
            this.acquireTokenFailureSubscription.unsubscribe();
        }
    }
}
