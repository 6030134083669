import {ClientDetail} from './../models/response/client-detail.model';
import {ClientRole, ClientUserDetail} from './../models/response/client-user-detail.model';
import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})

export class ClientDetailService {

    userformData: ClientRole;
    formData: ClientDetail;
    list: ClientDetail[];

    constructor(private http: HttpService) {
    }

    postClientUserDetail(clientId: number) {
        return this.http.post(`clients/${clientId}/users`, this.userformData);
    }

    postClientDetail() {
        return this.http.post(`clients/`, this.formData);
    }

    putClientUserDetail(clientId: number) {
        return this.http.patch(`clients/${clientId}/users`, this.userformData);
    }

    putClientDetail(id: string) {
        return this.http.patch(`clients/${id}`, this.formData);
    }

    getClientDetail(id: string) {
        return this.http.get<ClientDetail>(`clients/${id}`);
    }

    getClient(id: string) {
        return this.http.get<ClientDetail>(`clients/${id}/detail`);
    }

    refreshList(): Observable<ClientDetail[]> {
        return this.http.get<ClientDetail[]>(`clients/`);
    }

    getAllUsers(clientId: string): Observable<ClientUserDetail[]> {
        return this.http.get<ClientUserDetail[]>(`clients/${clientId}/users`);
    }
}
