// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { IEnvironment } from './environment.interface';

 const protectedResourceMapV: [string, string[]][] = [ 
     ['https://cyber-one-api.azurewebsites.net/api', ['2e027139-e4fe-4b88-801b-68c8a29414c0']],
  ['https://graph.microsoft.com/v1.0/', ['user.read']]
];
export const environment: IEnvironment = {
  production: false,
    baseUrl: 'https://cyber-one-api.azurewebsites.net/api',
  MSAL: {
      clientID: '2e027139-e4fe-4b88-801b-68c8a29414c0',
      redirectUri: 'https://portal.cyber-one.com.au/',
    cacheLocation: 'localStorage',
    piiLoggingEnabled: true,
    authority: 'https://login.microsoftonline.com/common',
    validateAuthority: true,
  protectedResourceMap:protectedResourceMapV

  }
};







/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


// import { IEnvironment } from './environment.interface';
//
// const protectedResourceMapV: [string, string[]][] = [
//    ['https://cyber-one-api-dev.azurewebsites.net/api', ['12344dda-74e2-405c-9253-0528bbd9e4be']],
//    ['https://graph.microsoft.com/v1.0/', ['user.read']]
// ];
// export const environment: IEnvironment = {
//    production: false,
//    baseUrl: 'https://cyber-one-api-dev.azurewebsites.net/api',
//    MSAL: {
//        clientID: '12344dda-74e2-405c-9253-0528bbd9e4be',
//        redirectUri: 'https://dev.cyber-one.com.au/',
//        cacheLocation: 'localStorage',
//        piiLoggingEnabled: true,
//        authority: 'https://login.microsoftonline.com/common',
//        validateAuthority: true,
//        protectedResourceMap: protectedResourceMapV
//
//    }
// };


