import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AlertDetails} from '../../../../models/graph/alert-details.model';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertService} from '../../../../services/alert.service';

import {UserService} from '../../../../services/user.service';

@Component({
    selector: 'log-ticket-dialog',
    templateUrl: './log-ticket-dialog.html',
})

export class LogTicketDialog {

    action: string;
    alertForm: FormGroup;
    alert: AlertDetails;
    dialogTitle: string;

    constructor(
        private alertService: AlertService,
        private route: ActivatedRoute,
        private userService: UserService,
        public dialogRef: MatDialogRef<LogTicketDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private router: Router,
        private _formBuilder: FormBuilder) {

        this.dialogTitle = 'Log Ticket In ConnectWise';

        var urlEndpointNoise = this.router.url;
        var v = urlEndpointNoise.split('(');

        if (this.alertService.alertDeets) {

            var alertTitle = '\n\nAlert Title:- ' + this.alertService.alertDeets.title;
            var alertDescription = '\n\nAlert Description:- ' + this.alertService.alertDeets.description;
            var alertId = '\n\nAlert Id:- ' + this.alertService.alertDeets.id;
            var alertTime = '\n\nAlert Time:- ' + this.alertService.alertDeets.createdDateTime;
            var alertSeverity = '\n\nAlert Severity:- ' + this.alertService.alertDeets.severity;
            var alertStatus = '\n\nAlert Status:- ' + this.alertService.alertDeets.status;

            var user = '\n\nAlert User:- No data';

            if (this.alertService.alertDeets.users[0]) {

                user = '\n\nAlert User:- ' + this.alertService.alertDeets.users[0].userPrincipalName;
            }

            var alertAssignedTo = '\n\nAlert AssignedTo:- ' + this.alertService.alertDeets.assignedTo.email;
            var invokedBy = '\n\nAlert InvokedBy:- ' + this.userService.getCurrentUser().displayableId;

            var provider = this.alertService.alertDeets.vendor.provider;

            if (provider == 'Office 365 Security and Compliance') {
                provider = 'Office 365 Security & Compliance';
            }

            var item = this.alertService.alertDeets.category;
            console.log("Category " + item);

            var SecOpsPortalReference = '\n\nCyber One:- \n\nhttps://portal.cyber-one.com.au' + v[0];
            this.alertForm = new FormGroup({
                'board': new FormControl('Alerts - Cyber Security'),
                'status': new FormControl('NewAlert'),
                'type': new FormControl('Security Alert'),
                'subType': new FormControl(provider),
                'item': new FormControl(item),
                'body': new FormControl('Hi, ' + alertTitle + alertDescription + alertId + alertTime + alertSeverity + alertStatus + user + alertAssignedTo + invokedBy + SecOpsPortalReference)
            });

        } else {

            this.alertForm = new FormGroup({
                'board': new FormControl(''),
                'status': new FormControl(''),
                'type': new FormControl(''),
                'subType': new FormControl(''),
                'item': new FormControl(''),
                'body': new FormControl('')
            });

        }

    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}