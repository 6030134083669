import {ClientConfirmationDialogComponent} from './../client-confirmation-dialog/client-confirmation-dialog.component';
import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {fuseAnimations} from '@fuse/animations';

import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';

import {ClientDetailService} from 'app/services/client-detail.service';
import {
    ClientConfirmationDialogActions,
    ClientDetail,
    ClientDialogActions
} from 'app/models/response/client-detail.model';
import {ClientDialog} from 'app/components/pages/client-management/client-dialog/client-dialog';
import {Location} from '@angular/common';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Subject, of} from 'rxjs';

@Component({
    selector: 'app-client-details',
    templateUrl: './client-details.component.html',
    styleUrls: ['./client-details.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ClientDetailsComponent implements OnInit {
    show: boolean = false;

    dialogRef: any;
    clientDetails: ClientDetail[];
    clientDetail: ClientDetail;
    displayedColumns = [
        'id',
        'name',
        'manage-users',
        'manage-subscriptions',
        'action'
    ];
    dataSource: MatTableDataSource<ClientDetail> = new MatTableDataSource();

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    constructor(
        private _clientDetailService: ClientDetailService,
        private dialog: MatDialog,
        private location: Location
    ) {
        this.getAllClients();
    }

    ngOnInit() {
        this.dataSource = new MatTableDataSource(this.clientDetails);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    getAllClients(): void {
        console.log('In get all clients');

        this._clientDetailService.refreshList().subscribe(
            (response) => {
                if (response) {
                    this.clientDetails = response;
                    this.show = true;

                    this.dataSource = new MatTableDataSource(response);
                    this.dataSource.sort = this.sort;
                } else {
                    console.log('Error ' + response);
                }
            },
            (err) => {
                console.log('Error');
                console.log('Error ' + err);
            }
        );
    }

    addClient(): void {
        this.dialogRef = this.dialog.open(ClientDialog, {
            panelClass: 'client-form-dialog',
            height: '600px',
            width: '600px',
            data: {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed().pipe(
                switchMap(
                    (response: {
                        action: ClientDialogActions;
                        clientForm: FormGroup;
                    }) => {
                        if (!response) {
                            return of(null);
                        }
                        const formData: FormGroup = response.clientForm;
                        this._clientDetailService.formData = formData.value;

                        return this._clientDetailService.postClientDetail();
                    }
                )
            )
            .subscribe((response) => {
                if (!response) {
                    return;
                }

                this.getAllClients();
            });
    }

    openDialog(id: string, input: ClientDetail, originalAppRegSecret: string = null): void {
        const unsubscribeDialog$: Subject<boolean> = new Subject<boolean>();

        if(!originalAppRegSecret) {
            originalAppRegSecret = input.appRegSecret;
        }
        
        console.log('Open Dialog Id ' + id);

        this.dialogRef = this.dialog.open(ClientDialog, {
            panelClass: 'client-form-dialog',
            height: '600px',
            width: '600px',
            data: {
                data: input,
                action: 'edit',
                originalAppRegSecret: originalAppRegSecret
            }
        });

        this.dialogRef
            .afterClosed()
            .pipe(
                switchMap(
                    (response: {
                        action: ClientDialogActions;
                        clientForm: FormGroup;
                    }) => {
                        if (!response) {
                            return of(null);
                        }

                        const actionType: ClientDialogActions = response.action;
                        const formData: FormGroup = response.clientForm;
                        this._clientDetailService.formData = formData.value;

                        switch (actionType) {
                            case ClientDialogActions.Save:
                                console.log('save');
                                console.log(response);

                                return this._clientDetailService.putClientDetail(id).pipe(
                                    map((response) => {
                                        return {
                                            response: response,
                                            actionType: actionType
                                        };
                                    })
                                );

                            case ClientDialogActions.Delete:
                                console.log('delete');
                                this._clientDetailService.formData.inActive = true;
                                return this._clientDetailService.putClientDetail(id).pipe(
                                    map((response) => {
                                        return {
                                            response: response,
                                            actionType: actionType
                                        };
                                    })
                                );

                            case ClientDialogActions.ConfirmationBeforeSave:
                                const confirmationDialogRef = this.dialog.open(
                                    ClientConfirmationDialogComponent,
                                    {
                                        panelClass: 'client-form-dialog',
                                        height: '230px',
                                        width: '600px'
                                    }
                                );
                                
                                return confirmationDialogRef.afterClosed().pipe(
                                    switchMap((response: {action: ClientConfirmationDialogActions}) => {
                                        if(!response || response.action === ClientConfirmationDialogActions.Cancel) {
                                            return of(null);
                                        }

                                        if(response.action === ClientConfirmationDialogActions.Back) {
                                            const data = formData.value;

                                            // Asssuming the form property names are the same as the ClientDetail model
                                            const existingDetails: ClientDetail = {
                                                iD: id,
                                                ...data,
                                                inActive:  this._clientDetailService.formData.inActive
                                            };

                                            this.openDialog(id, existingDetails, originalAppRegSecret);
                                            unsubscribeDialog$.next(true);
                                            return of(null);
                                        }

                                        return this._clientDetailService.putClientDetail(id).pipe(
                                            map((response) => {
                                                return {
                                                    response: response,
                                                    actionType: actionType
                                                };
                                            })
                                        );
                                    })
                                );
                            default:
                                throw new Error('Invalid action type: ' + actionType);
                        }
                    }
                ),
                takeUntil((unsubscribeDialog$))
            )
            .subscribe((response) => {
                unsubscribeDialog$.next(true);

                if (!response) {
                    return;
                }

                this.getAllClients();
            });
    }
}
