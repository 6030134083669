import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';

import {ClientUserDetail} from 'app/models/response/client-user-detail.model';

interface ClientUserRole {
    value: string;
}

@Component({
    selector: 'client-user-dialog',
    templateUrl: './client-user-dialog.html',
    styleUrls: ['./client-user-dialog.scss'],

})

export class ClientUserDialog {
    action: string;
    clientUserForm: FormGroup;
    clientUser: ClientUserDetail;
    dialogTitle: string;

    clientRoles: ClientUserRole[] = [
        {value: 'Admin'},
        {value: 'Client'}
    ];

    constructor(
        public dialogRef: MatDialogRef<ClientUserDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder) {

        this.action = data.action;

        if (this.action == 'new') {

            this.dialogTitle = 'New User';
            this.clientUser = new ClientUserDetail();

        }

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit User';
            this.clientUser = data.data;
        } else {
            this.dialogTitle = 'New User';
            this.clientUser = new ClientUserDetail();
        }

        this.clientUserForm = this.createClientUserForm();

    }

    createClientUserForm(): FormGroup {
        return this._formBuilder.group({
            name: [this.clientUser.userName],
            role: [this.clientUser.role],
            roles: [this.clientRoles[1]],
            clientName: [this.clientUser.clientName]
        });
    }

}