<!-- <div *ngIf="show" ">
    <!-- <div style=" width: 100%;" [ngStyle]="{ 'height': (screenHeight-150)+ 'px' }"> -->

<!-- </div> -->


<div *ngIf="error" id="err" style=" position:absolute;
left:50%;
top:50%;
transform: translate(-50%, -50%);
text-align:center;
margin: 0 auto;">

    <h1 style="color: red;text-align: center;">Cyber One server is not responding.</h1>

</div>


<!-- FUSE Splash Screen -->
<div *ngIf="!show" id="fuse-splash-screen">

    <div class="center">

        <div class="logo">
            <img src="assets/images/logos/Quorum.png" width="128">
        </div>

        <!-- Material Design Spinner -->
        <div class="spinner-wrapper">
            <div class="spinner">
                <div class="inner">
                    <div class="gap"></div>
                    <div class="left">
                        <div class="half-circle"></div>
                    </div>
                    <div class="right">
                        <div class="half-circle"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- / Material Design Spinner -->

    </div>

</div>
<!-- / FUSE Splash Screen -->


<div *ngIf="show && !error" style=" width: 100%; height: 100%;">

    <ngx-powerbi-component accessToken={{accessToken}}
                           embedUrl={{embedUrl}}
                           id={{id}}
                           tokenType={{tokenType}}
                           type="report"
    >
    </ngx-powerbi-component>
