import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {Location} from '@angular/common';

import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';

import {ClientSubscription} from 'app/models/response/client-subscriptions.model';
import {ClientSubscriptionsService} from 'app/services/client-subscriptions.service';
import {ClientDetailService} from 'app/services/client-detail.service';
import {
    ClientSubscriptionDialog
} from 'app/components/pages/client-management/client-subscription-dialog/client-subscription-dialog'

@Component({
    selector: 'app-client-subscriptions',
    templateUrl: './client-subscriptions.component.html',
    styleUrls: ['./client-subscriptions.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ClientSubscriptionsComponent implements OnInit {
    show: boolean = false

    clientName: string
    dialogRef: any
    clientSubscriptions: ClientSubscription[]

    displayedColumns = ['id', 'displayName', 'subscriptionId', 'state', 'authorizationSource', 'editSubscription', 'deleteSubscription'];
    dataSource = new MatTableDataSource(this.clientSubscriptions);

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    constructor(
        private _clientSubscriptionService: ClientSubscriptionsService,
        private _clientDetailService: ClientDetailService,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private location: Location
    ) {
        this.getAllSubscriptions();
    }

    ngOnInit(): void {
        this.dataSource.sort = this.sort;
    }

    getAllSubscriptions(): void {
        const clientId: string = this.route.snapshot.paramMap.get('clientId');
        this._clientDetailService.getClientDetail(clientId)
            .subscribe(response => {
                    if (response) {
                        this.clientName = response.name;
                    }
                },
                err => {
                    console.log("Error " + err);
                });

        this._clientSubscriptionService.getClientSubscriptions(clientId)
            .subscribe(response => {
                if (response) {
                    this.show = true;
                    this.dataSource = new MatTableDataSource(response);
                    this.dataSource.sort = this.sort;
                } else {
                    this.show = false;
                }
            })
    }

    addClientSubscription(): void {
        this.dialogRef = this.dialog.open(ClientSubscriptionDialog, {
            panelClass: 'client-subscription-dialog',
            height: '600px',
            width: '600px',
            data: {
                action: 'new'
            }
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }

                const clientId: number = +this.route.snapshot.paramMap.get('clientId');
                this.setClientSubscriptionData(response, clientId);

                this._clientSubscriptionService.postClientSubscriptions(clientId)
                    .subscribe(response => {
                            this.refreshSubscriptions(response);
                        },
                        err => {
                            console.log("Error " + err);
                        });
            });
    }

    editSubscription(input: ClientSubscription): void {
        this.dialogRef = this.dialog.open(ClientSubscriptionDialog, {
            panelClass: 'client-subscription-dialog',
            height: '600px',
            width: '600px',
            data: {
                data: input,
                action: 'edit'
            }
        });
        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const clientId: number = +this.route.snapshot.paramMap.get('clientId');
                this.setClientSubscriptionData(response, clientId);
                this._clientSubscriptionService.subscriptionData.id = input.id;

                this._clientSubscriptionService.putClientSubscriptions(clientId)
                    .subscribe(response => {
                            this.refreshSubscriptions(response);
                        },
                        err => {
                            console.log("Error " + err);
                        });
            });
    }

    setClientSubscriptionData(input: any, clientId: number): void {
        const formData: FormGroup = input[1];

        this._clientSubscriptionService.subscriptionData = new ClientSubscription();
        this._clientSubscriptionService.subscriptionData.displayName = formData.value.displayName;
        this._clientSubscriptionService.subscriptionData.subscriptionId = formData.value.subscriptionId.trim();
        this._clientSubscriptionService.subscriptionData.state = formData.value.state;
        this._clientSubscriptionService.subscriptionData.authorizationSource = formData.value.authorizationSource;
        this._clientSubscriptionService.subscriptionData.clientId = clientId;
    }

    removeSubscription(id: number, displayName: string): void {
        if (confirm("Are you sure you want to delete subscription: " + displayName)) {
            this._clientSubscriptionService.deleteClientSubscriptions(id)
                .subscribe(response => {
                        this.refreshSubscriptions(response);
                    },
                    err => {
                        console.log("Error " + err);
                    });
        } else {
            this.getAllSubscriptions();
        }
    }

    closeSubscriptions(): void {
        this.location.back();
    }

    refreshSubscriptions(response: any): void {
        if (response) {
            this.getAllSubscriptions();
        } else {
            console.log("Error " + response);
        }
    }
}

