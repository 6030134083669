import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

import {MsalService} from '@azure/msal-angular';

@Injectable({
    providedIn: 'root'
})
export class GraphService {

    constructor(private http: HttpClient, private sanitizer: DomSanitizer,
                private authService: MsalService) {
    }

    getUserPhoto(): Observable<SafeUrl> {

        console.log("Get User Photo");
        console.log("Upn " + this.authService.getUser().displayableId);

        let requestUrl = `https://graph.microsoft.com/v1.0/me/photo/$value`;

        //+this.authService.getUser().displayableId

        return this.http.get(requestUrl, {responseType: "blob"}).pipe(map(result => {

            let url = window.URL;

            return this.sanitizer.bypassSecurityTrustUrl(url.createObjectURL(result));
        }));
    }
}