import {Component, OnInit} from '@angular/core';
import {DashboardService} from 'app/services/dashboard.service';
import {UserService} from 'app/services/user.service';
import {ClientDetailService} from 'app/services/client-detail.service';
import {MsalService} from '@azure/msal-angular';
import {map} from "rxjs/operators";
import {of} from "rxjs";

@Component({
    selector: 'app-m365-compliance-report',
    templateUrl: './m365-compliance-report.component.html'
})
export class M365ComplianceReportComponent implements OnInit {
    show: boolean = false;
    error: boolean = false;
    complianceDisabled: boolean = true;

    public tokenType = 'Embed';
    // For Power BI Embed
    public accessToken: string;
    public embedUrl: string;
    public id: string;

    private _reportName: string = '365Compliance';

    constructor(
        private dashboardService: DashboardService,
        private userService: UserService,
        private clientDetailService: ClientDetailService,
        private authService: MsalService
    ) {
    }

    ngOnInit(): void {
        this._getPowerBIEmbedConfig();
    }

    private _getPowerBIEmbedConfig(): void {
        this.userService.getUserRole().pipe(map(userRole => {
            if (userRole.role == 'Admin') {
                return of(true);
            } else {
                return this.clientDetailService.getClient(this.authService.clientId)
                    .pipe(map(client => client.syncSecurityAndCompliance == 'Enabled'));
            }
        })).subscribe(shouldSync => {
            if (shouldSync) {
                this.dashboardService
                    .getPowerBIEmbedConfig(this._reportName)
                    .subscribe(
                        (response) => {
                            if (response) {
                                this.show = true;
                                this.error = false;
                                this.complianceDisabled = false;
                                this.accessToken = response.accessToken;
                                this.embedUrl = response.embedUrl;
                                this.id = response.id;
                            } else {
                                this.show = true;
                                this.error = true;
                                this.complianceDisabled = false;
                                console.log('Error', response);
                            }
                        },
                        (err) => {
                            console.log('Error');
                            console.log('Error', err);
                        }
                    );
            } else {
                this.show = true;
                this.error = false;
                this.complianceDisabled = true;
            }
        });
    }
}
