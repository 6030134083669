import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {ASCAlertDetailsComponent} from './asc-alert-details.component';

import {CommonComponentsModule} from '../../common/common-components.module';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTabsModule} from '@angular/material/tabs';

import {FuseWidgetModule} from '@fuse/components/widget/widget.module';

import {MatCardModule} from '@angular/material/card';

import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';

import {MatToolbarModule} from '@angular/material/toolbar';

import {AscLogTicketDialog} from '../asc-alert-details/asc-alert-details-logticket-cw/asc-log-ticket-dialog';

const components = [
    ASCAlertDetailsComponent,
    AscLogTicketDialog

];

@NgModule({
    imports: [
        CommonModule,
        CommonComponentsModule,
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatExpansionModule,
        MatTabsModule,
        FuseWidgetModule,
        MatCardModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatFormFieldModule,
        MatToolbarModule

    ],

    declarations: components,
    exports: components
})

export class ASCAlertDetailsPageModule {
}
