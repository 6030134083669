import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {MsalService} from '@azure/msal-angular';
import {UserService} from '../services/user.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {
    role: string;
    constructor(
        private router: Router,
        private userService: UserService
    ) {}

    canActivate(): Observable<boolean> {
        return this.userService
            .getUserRole()
            .pipe(
                map((response) => {
                    if (response && response.role === 'Admin') {
                        return true;
                    } else {
                        this.router.navigate(['/dashboard']);
                        return false;
                    }
                })
            );
    }
}
