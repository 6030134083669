<!-- FUSE Splash Screen -->
<div *ngIf="!show" id="fuse-splash-screen">

    <div class="center">

        <div class="logo">
            <img src="assets/images/logos/Quorum.png" width="128">
        </div>

        <!-- Material Design Spinner -->
        <div class="spinner-wrapper">
            <div class="spinner">
                <div class="inner">
                    <div class="gap"></div>
                    <div class="left">
                        <div class="half-circle"></div>
                    </div>
                    <div class="right">
                        <div class="half-circle"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- / Material Design Spinner -->

    </div>

</div>
<!-- / FUSE Splash Screen -->


<div *ngIf="show" class="page-layout carded fullwidth inner-scroll">


    <div class="top-bg quorum-color"></div>

    <div class="center">


        <!-- HEADER -->

        <div class="header quorum-color" fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="center center"
             fxLayoutAlign.gt-xs="space-between center">

            <div class="logo mb-24 mb-sm-0" fxLayout="row" fxLayoutAlign="start start">

                <div>

                    <span [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}"
                          class="logo-text h1">{{ alertDetails.title }}</span>


                    <span [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}" class="logo-text h1">

                        <app-severity-level [level]="alertDetails.severity.toLowerCase()" size="large">
                        </app-severity-level>

                    </span>

                    <span [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}"
                          class="logo-text h1">{{ alertDetails.status }}</span>

                </div>


            </div>


        </div>

        <!-- HEADER -->


        <!-- Main Content -->


        <div class="content-card">

            <div class="page-layout carded fullwidth inner-scroll" fxFlexFill fxHide.yba-min-height fxLayout="row"
                 fxLayout.xs="column" style="margin: 20px;">


                <!-- Left Card -->

                <div class="sec1" fxFlex="80" fxFlex.xs="80" fxLayoutGap="32px"
                     style="box-sizing: border-box; margin: 20px;">
                    <div fxLayout="row">

                        <!-- Alert Main Info -->

                        <div fxFlex="80">

                            <mat-card class="card-demo mat-card">

                                <mat-card-content class="large mat-card-content">


                                    <div fxLayout="row">


                                        <div fxFlex="33">

                                            <div class="h3">
                                                Created
                                            </div>

                                            <div class="h3" style="margin-top: 5px;">
                                                {{ alertDetails.createdDateTime | date : 'M/d/yyyy, h:mm a' }}

                                            </div>


                                        </div>

                                        <div fxFlex="33">

                                            <div class="h3">
                                                User
                                            </div>

                                            <div class="h3" style="margin-top: 5px;">

                                                <div>
                                                    <app-user-logo *ngIf="alertDetails.userAccountDevices[0]"
                                                                   [user]="alertDetails.userAccountDevices[0]">
                                                    </app-user-logo>
                                                    <div>

                                                        {{ alertDetails.userAccountDevices[0] ? alertDetails.userAccountDevices[0].displayName : 'No data' }}

                                                    </div>

                                                </div>
                                            </div>


                                        </div>

                                        <div fxFlex="33">
                                            <div class="h3">
                                                Host
                                            </div>

                                            <div class="h3" style="margin-top: 5px;">
                                                {{ alertDetails.hosts[0] != null ? alertDetails.hosts[0].fqdn : 'No data' }}
                                            </div>
                                        </div>

                                    </div>


                                    <div fxLayout="row" style="margin-top: 20px;">
                                        <div fxFlex="33">

                                            <div class="h3">
                                                Assigned to
                                            </div>


                                            <div class="h3"
                                                 style="margin-top: 5px; white-space: normal; overflow-wrap: break-word;">

                                             

                                                    <span>
                                                 <app-user-logo *ngIf="alertDetails.assignedTo"
                                                                [user]="alertDetails.assignedTo">
                                                    </app-user-logo>
                                                </span>
                                                <span style="white-space: normal; overflow-wrap: break-word;">
                                                        {{ alertDetails.assignedTo ? alertDetails.assignedTo.displayName : 'No data' }}
                                                    </span>


                                            </div>


                                        </div>

                                        <div fxFlex="33">

                                            <div class="h3">
                                                Id
                                            </div>

                                            <div class="h3"
                                                 style="margin-top: 5px; white-space: normal; overflow-wrap: break-word;">
                                                {{ alertDetails.id }}
                                            </div>
                                        </div>


                                        <div fxFlex="33">

                                            <div class="h3">
                                                Feedback
                                            </div>

                                            <div class="h3" style="margin-top: 5px;">
                                                {{ alertDetails.feedback ? alertDetails.feedback : 'No data' }}
                                            </div>
                                        </div>

                                    </div>


                                    <div fxLayout="row" style="margin-top: 20px;">

                                        <div fxFlex="33">

                                            <div class="h3">
                                                CW Ticket Id
                                            </div>

                                            <div class="h3" style="margin-top: 5px;">

                                                {{ alertDetails.id_Ticket_ConnectWise ? alertDetails.id_Ticket_ConnectWise : 'No data' }}

                                            </div>
                                        </div>

                                        <div fxFlex="33">

                                            <div class="h3">
                                                CW Ticket Status
                                            </div>

                                            <div class="h3" style="margin-top: 5px;">
                                                {{ alertDetails.status_Ticket_ConnectWise ? alertDetails.status_Ticket_ConnectWise : 'No data' }}
                                            </div>
                                        </div>

                                        <div fxFlex="33">
                                            <p></p>
                                        </div>

                                    </div>

                                    <div fxLayout="row" style="margin-top: 20px; margin-bottom: 20px;">

                                        <div fxFlex="100">

                                            <div class="h3">
                                                Description
                                            </div>

                                            <div class="h3" style="margin-top: 5px;">
                                                {{ alertDetails.description ? alertDetails.description : 'No data' }}
                                            </div>
                                        </div>

                                    </div>


                                    <!-- #region detection-ids -->
                                    <div *ngIf="alertDetails.detectionIds && alertDetails.detectionIds.length"
                                         class="list-item" style="margin-top: 20px;">

                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>Detection IDs</mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div fxFlex fxLayout="column">

                                                <div *ngFor="let item of alertDetails.detectionIds">
                                                    {{ item }}
                                                </div>

                                            </div>

                                        </mat-expansion-panel>

                                    </div>
                                    <!-- #endregion detection-ids -->


                                    <div *ngIf="alertDetails.cloudAppStates && alertDetails.cloudAppStates.length"
                                         class="list-item" style="margin-top: 20px;">

                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>Cloud Application States</mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div fxFlex fxLayout="column">


                                                <div fxLayout="row" style="margin-top: 20px;">


                                                    <div fxFlex="33">

                                                        <div class="h3">
                                                            Destination Service Name
                                                        </div>


                                                    </div>

                                                    <div fxFlex="33">

                                                        <div class="h3">
                                                            Destination Service Ip
                                                        </div>


                                                    </div>

                                                    <div fxFlex="33">
                                                        <div class="h3">
                                                            Risk Score
                                                        </div>


                                                    </div>

                                                </div>


                                                <div *ngFor="let item of alertDetails.cloudAppStates">

                                                    <div fxLayout="row" style="margin-top: 20px;">


                                                        <div fxFlex="33">


                                                            <div class="h3" style="margin-top: 10px;">
                                                                {{ item.destinationServiceName || 'No Data' }}

                                                            </div>


                                                        </div>

                                                        <div fxFlex="33">


                                                            <div class="h3" style="margin-top: 10px;">
                                                                {{ item.destinationServiceIp || 'No Data' }}
                                                            </div>
                                                        </div>

                                                        <div fxFlex="33">

                                                            <div class="h3" style="margin-top: 10px;">
                                                                {{ item.riskScore || 'No Data' }}
                                                            </div>
                                                        </div>

                                                    </div>


                                                </div>

                                            </div>
                                        </mat-expansion-panel>

                                    </div>


                                    <!-- #endregion cloud-app-states -->


                                    <!-- #region vulnerability-states -->

                                    <div
                                        *ngIf="alertDetails.vulnerabilityStates && alertDetails.vulnerabilityStates.length"
                                        class="list-item" style="margin-top: 20px;">

                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>Vulnerability States</mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div fxFlex fxLayout="column">

                                                <div *ngFor="let item of alertDetails.vulnerabilityStates">
                                                    {{ item }}
                                                </div>

                                            </div>

                                        </mat-expansion-panel>

                                    </div>
                                    <!-- #endregion vulnerability-states -->


                                    <!-- #region tags -->


                                    <div *ngIf="alertDetails.tags && alertDetails.tags.length" class="list-item"
                                         style="margin-top: 20px;">

                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>Tags</mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div fxFlex fxLayout="column">

                                                <div *ngFor="let item of alertDetails.tags">
                                                    {{ item }}
                                                </div>

                                            </div>

                                        </mat-expansion-panel>

                                    </div>

                                    <!-- #endregion tags -->
                                    <!-- #region source-materials -->

                                    <div *ngIf="alertDetails.sourceMaterials && alertDetails.sourceMaterials.length"
                                         class="list-item" style="margin-top: 20px;">

                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>Source Materials</mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div fxFlex fxLayout="column">

                                                <div *ngFor="let item of alertDetails.sourceMaterials"
                                                     style="margin-top: 10px;">

                                                    <a href={{item}} style="color: white;">{{ item }}</a>

                                                </div>

                                            </div>

                                        </mat-expansion-panel>

                                    </div>

                                    <!-- #endregion source-materials -->

                                    <!-- #region malware-states -->
                                    <div *ngIf="alertDetails.malwareStates && alertDetails.malwareStates.length"
                                         class="list-item" style="margin-top: 20px;">


                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>Malware States</mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div fxFlex fxLayout="column">

                                                <div *ngFor="let item of alertDetails.malwareStates">

                                                    <div fxLayout="row" style="margin-top: 20px;">


                                                        <div fxFlex="33">

                                                            <div class="h3">
                                                                Name
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">
                                                                {{ item.name || 'No Data' }}

                                                            </div>


                                                        </div>

                                                        <div fxFlex="33">

                                                            <div class="h3">
                                                                Severity
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">

                                                                {{ item.severity || 'No Data' }}


                                                            </div>
                                                        </div>

                                                        <div fxFlex="33">
                                                            <div class="h3">
                                                                Category
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">

                                                                {{ item.category || 'No Data' }}


                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div fxLayout="row" style="margin-top: 20px;">


                                                        <div fxFlex="33">

                                                            <div class="h3">
                                                                Family
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">

                                                                {{ item.family || 'No Data' }}


                                                            </div>


                                                        </div>

                                                        <div fxFlex="33">

                                                            <div class="h3">
                                                                Was Running
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">

                                                                {{ item.wasRunning || 'No Data' }}


                                                            </div>
                                                        </div>

                                                        <div fxFlex="33">
                                                            <div class="h3">
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>

                                            </div>

                                        </mat-expansion-panel>


                                    </div>
                                    <!-- #endregion malware-states -->


                                    <!-- #region network-connections -->


                                    <div
                                        *ngIf="alertDetails.networkConnections && alertDetails.networkConnections.length"
                                        class="list-item" style="margin-top: 20px;">


                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>Network Connections</mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div fxFlex fxLayout="column">

                                                <div *ngFor="let item of alertDetails.networkConnections">

                                                    <div fxLayout="row" style="margin-top: 20px;">


                                                        <div fxFlex="20">

                                                            <div class="h3">
                                                                Application name
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">
                                                                {{ item.applicationName || 'No Data' }}


                                                            </div>


                                                        </div>

                                                        <div fxFlex="20">

                                                            <div class="h3">
                                                                Direction
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">

                                                                {{ item.direction || 'No Data' }}

                                                            </div>
                                                        </div>

                                                        <div fxFlex="20">
                                                            <div class="h3">
                                                                Domain registered
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">
                                                                {{ item.domainRegisteredDateTime || 'No Data' }}

                                                            </div>
                                                        </div>

                                                        <div fxFlex="20">
                                                            <div class="h3">
                                                                Local DNS name
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">

                                                                {{ item.localdnsname || 'No Data' }}


                                                            </div>
                                                        </div>

                                                        <div fxFlex="20">
                                                            <div class="h3">
                                                                Protocol
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">

                                                                {{ item.protocol || 'No Data' }}


                                                            </div>
                                                        </div>


                                                    </div>

                                                    <div fxLayout="row" style="margin-top: 20px;">


                                                        <div fxFlex="20">

                                                            <div class="h3">
                                                                Source address
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">

                                                                {{ item.sourceAddress || 'No Data' }}


                                                            </div>


                                                        </div>

                                                        <div fxFlex="20">

                                                            <div class="h3">
                                                                Source port
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">

                                                                {{ item.sourceport || 'No Data' }}


                                                            </div>
                                                        </div>

                                                        <div fxFlex="20">

                                                            <div class="h3">
                                                                Status
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">

                                                                {{ item.status || 'No Data' }}


                                                            </div>
                                                        </div>

                                                        <div fxFlex="20">

                                                            <div class="h3">
                                                                Risk score
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">


                                                                {{ item.riskscore || 'No Data' }}


                                                            </div>
                                                        </div>

                                                        <div fxFlex="20">

                                                            <div class="h3">
                                                                URL parameters
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">

                                                                {{ item.urlParameters || 'No Data' }}


                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div fxLayout="row" style="margin-top: 20px;">


                                                        <div fxFlex="20">

                                                            <div class="h3">
                                                                NAT destination address
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">


                                                                {{ item.natDestinationAddress || 'No Data' }}


                                                            </div>


                                                        </div>

                                                        <div fxFlex="20">

                                                            <div class="h3">
                                                                NAT destination port
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">


                                                                {{ item.natDestinationPort || 'No Data' }}


                                                            </div>
                                                        </div>

                                                        <div fxFlex="20">

                                                            <div class="h3">
                                                                NAT source address
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">

                                                                {{ item.natSourceAddress || 'No Data' }}


                                                            </div>
                                                        </div>

                                                        <div fxFlex="20">

                                                            <div class="h3">
                                                                NAT source port
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">
                                                                {{ item.natSourcePort || 'No Data' }}

                                                            </div>
                                                        </div>

                                                        <div fxFlex="20">


                                                        </div>

                                                    </div>

                                                    <div fxLayout="row" style="margin-top: 20px;">


                                                        <div fxFlex="20">

                                                            <div class="h3">
                                                                Destination domain
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">


                                                                {{ item.destinationDomain || 'No Data' }}


                                                            </div>


                                                        </div>

                                                        <div fxFlex="20">

                                                            <div class="h3">
                                                                Destination port
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">


                                                                {{ item.destinationPort || 'No Data' }}


                                                            </div>
                                                        </div>

                                                        <div fxFlex="20">

                                                            <div class="h3">
                                                                Destination URL
                                                            </div>

                                                            <div class="h3"
                                                                 style="margin-top: 5px; white-space: normal; overflow-wrap: break-word;">

                                                                {{ item.destinationUrl || 'No Data' }}


                                                            </div>
                                                        </div>

                                                        <div fxFlex="20">

                                                            <div class="h3">
                                                                Destination IP address
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">
                                                                {{ item.destinationAddress || 'No Data' }}

                                                            </div>
                                                        </div>

                                                        <div fxFlex="20">


                                                        </div>

                                                    </div>


                                                </div>

                                            </div>

                                        </mat-expansion-panel>


                                    </div>


                                    <!-- #endregion network-connections -->

                                    <!-- #region  hosts -->

                                    <div *ngIf="alertDetails.hosts && alertDetails.hosts.length"
                                         class="list-item" style="margin-top: 20px;">


                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>Hosts</mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div fxFlex fxLayout="column">

                                                <div *ngFor="let item of alertDetails.hosts">

                                                    <div fxLayout="row" style="margin-top: 20px;">


                                                        <div fxFlex="33">

                                                            <div class="h3">
                                                                FQDN
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">
                                                                {{ item.fqdn != null ? item.fqdn : 'No Data' }}
                                                            </div>
                                                        </div>

                                                        <div fxFlex="33">

                                                            <div class="h3">
                                                                NetBios name
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">
                                                                {{ item.netBiosName || 'No Data' }}

                                                            </div>
                                                        </div>

                                                        <div fxFlex="33">
                                                            <div class="h3">
                                                                Operating system
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">
                                                                {{ item.os || 'No Data' }}

                                                            </div>
                                                        </div>


                                                    </div>

                                                    <div fxLayout="row" style="margin-top: 20px;">


                                                        <div fxFlex="33">

                                                            <div class="h3">
                                                                Private IP adress
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">

                                                                {{ item.privateIpAddress || 'No Data' }}


                                                            </div>


                                                        </div>

                                                        <div fxFlex="33">

                                                            <div class="h3">
                                                                Public IP adress
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">
                                                                {{ item.publicIpAddress || 'No Data' }}

                                                            </div>
                                                        </div>

                                                        <div fxFlex="33">
                                                            <div class="h3">
                                                                Risk score
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">
                                                                {{ item.riskScore || 'No Data' }}

                                                            </div>
                                                        </div>


                                                    </div>

                                                    <div fxLayout="row" style="margin-top: 20px;">


                                                        <div fxFlex="33">

                                                            <div class="h3">
                                                                Is AzureAD joined
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">

                                                                {{ item.isAzureAdJoined || 'No Data' }}

                                                            </div>


                                                        </div>

                                                        <div fxFlex="33">

                                                            <div class="h3">
                                                                Is AzureAD registered
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">

                                                                {{ item.isAzureAdRegistered || 'No Data' }}


                                                            </div>
                                                        </div>

                                                        <div fxFlex="33">
                                                            <div class="h3">
                                                                Is hybrid Azure domain joined
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">

                                                                {{ item.isHybridAzureDomainJoined || 'No Data' }}


                                                            </div>
                                                        </div>


                                                    </div>

                                                </div>

                                            </div>

                                        </mat-expansion-panel>


                                    </div>

                                    <!-- #endregion hosts -->

                                    <!-- #region  users -->


                                    <div
                                        *ngIf="alertDetails.userAccountDevices && alertDetails.userAccountDevices.length"
                                        class="list-item" style="margin-top: 20px;">


                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>User Accounts</mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div fxFlex fxLayout="column">

                                                <div *ngFor="let user of alertDetails.userAccountDevices">

                                                    <div fxLayout="row" style="margin-top: 20px;">


                                                        <div fxFlex="30">
                                                            <div class="h3">
                                                                <app-user-logo *ngIf="user" [user]="user" size="100">
                                                                </app-user-logo>
                                                            </div>
                                                        </div>

                                                        <div fxLayout="column">


                                                            <div fxFlex="50" style="margin-top: 20px;">

                                                                <div class="h3">
                                                                    Account name
                                                                </div>

                                                                <div class="h3" style="margin-top: 5px;">
                                                                    {{ user.displayName }}

                                                                </div>
                                                            </div>

                                                            <div fxFlex="50" style="margin-top: 20px;">
                                                                <div class="h3">
                                                                    User principal name
                                                                </div>

                                                                <div class="h3" style="margin-top: 5px;">
                                                                    {{ user.upn }}
                                                                </div>
                                                            </div>

                                                        </div>


                                                    </div>

                                                    <div fxLayout="row" style="margin-top: 20px;">


                                                        <div fxFlex="33" fxLayout="column">

                                                            <div fxFlex="50" style="margin-top: 20px;">

                                                                <div class="h3">
                                                                    Job title
                                                                </div>

                                                                <div class="h3" style="margin-top: 5px;">
                                                                    {{ user.jobTitle || 'No Data' }}


                                                                </div>


                                                            </div>

                                                            <div fxFlex="50" style="margin-top: 20px;">

                                                                <div class="h3">
                                                                    Office location
                                                                </div>

                                                                <div class="h3" style="margin-top: 5px;">
                                                                    {{ user.officeLocation ? user.officeLocation : 'No data' }}

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div fxFlex="33" fxLayout="column" style="margin-top: 20px;">

                                                            <div fxFlex="50">

                                                                <div class="h3">
                                                                    Manager
                                                                </div>

                                                                <div class="h3">

                                                                    {{ user.manager != null ? user.manager.displayName : 'No Data' }}


                                                                </div>
                                                            </div>


                                                            <div fxFlex="50">

                                                                <div class="h3">
                                                                    Email
                                                                </div>

                                                                <div class="h3" style="margin-top: 5px;">
                                                                    {{ user.email ? user.email : 'No data' }}


                                                                </div>
                                                            </div>


                                                        </div>

                                                        <div fxFlex="33" fxLayout="column" style="margin-top: 20px;">

                                                            <div class="h3">
                                                                Contact via
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">
                                                                {{ user.contactVia ? user.contactVia : 'No data' }}

                                                            </div>
                                                        </div>


                                                    </div>

                                                    <div fxLayout="row" style="margin-top: 20px;">
                                                        <div fxFlex="33" fxLayout="column">

                                                            <div fxFlex="50" style="margin-top: 20px;">

                                                                <div class="h3">
                                                                    Email role
                                                                </div>

                                                                <div class="h3" style="margin-top: 5px;">

                                                                    {{ user.emailRole ? user.emailRole : 'No data' }}


                                                                </div>


                                                            </div>

                                                            <div fxFlex="50" style="margin-top: 20px;">

                                                                <div class="h3">
                                                                    Domain name
                                                                </div>

                                                                <div class="h3" style="margin-top: 5px;">
                                                                    {{ user.domainName ? user.domainName : 'No data' }}

                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div fxFlex="33" style="margin-top: 20px;">

                                                            <div class="h3">
                                                                Risk score
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">
                                                                {{ user.riskScore ? user.riskScore : 'No data' }}

                                                            </div>
                                                        </div>

                                                        <div fxFlex="33" style="margin-top: 20px;">

                                                            <div class="h3">
                                                                Logon ID
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">
                                                                {{ user.logonId ? user.logonId : 'No data' }}

                                                            </div>
                                                        </div>


                                                    </div>


                                                </div>

                                            </div>

                                        </mat-expansion-panel>


                                    </div>


                                    <!-- #endregion users -->

                                    <!-- #region files -->

                                    <div *ngIf="alertDetails.files && alertDetails.files.length"
                                         class="list-item" style="margin-top: 20px;">


                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>Files</mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div fxFlex fxLayout="column">

                                                <div *ngFor="let item of alertDetails.files">

                                                    <div fxLayout="row" style="margin-top: 20px;">


                                                        <div fxFlex="33">

                                                            <div class="h3">
                                                                Name
                                                            </div>

                                                            <div class="h3"
                                                                 style="margin-top: 5px; overflow-wrap: anywhere;">
                                                                {{ item.name || 'No Data' }}

                                                            </div>


                                                        </div>

                                                        <div fxFlex="33">

                                                            <div class="h3">
                                                                Path
                                                            </div>

                                                            <div class="h3"
                                                                 style="margin-top: 5px; overflow-wrap: anywhere;">
                                                                {{ item.path || 'No Data' }}
                                                            </div>
                                                        </div>

                                                        <div fxFlex="33">
                                                            <div class="h3">
                                                                Risk Score
                                                            </div>

                                                            <div class="h3" style="margin-top: 5px;">
                                                                {{ item.riskScore || 'No Data' }}
                                                            </div>
                                                        </div>

                                                        <div fxFlex="33">
                                                            <div class="h3">
                                                                File Hash Type
                                                            </div>

                                                            <div class="h3"
                                                                 style="margin-top: 5px; overflow-wrap: break-word;">
                                                                {{ item.fileHash?.hashType || 'No Data' }}
                                                            </div>


                                                        </div>

                                                        <div fxFlex="33">
                                                            <div class="h3">
                                                                File Hash Value
                                                            </div>

                                                            <div class="h3"
                                                                 style="margin-top: 5px; overflow-wrap: break-word;">
                                                                {{ item.fileHash?.hashValue || 'No Data' }}
                                                            </div>


                                                        </div>


                                                    </div>

                                                </div>
                                            </div>

                                        </mat-expansion-panel>


                                    </div>

                                    <!-- #endregion files -->
                                    <!-- #region comments -->


                                    <div *ngIf="alertDetails.comments && alertDetails.comments.length"
                                         class="list-item" style="margin-top: 20px;">

                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>Comments</mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div fxFlex fxLayout="column">

                                                <div *ngFor="let item of alertDetails.comments">
                                                    {{ item }}
                                                </div>

                                            </div>

                                        </mat-expansion-panel>

                                    </div>


                                    <!-- #endregion comments -->

                                </mat-card-content>

                            </mat-card>


                        </div>

                        <!-- Alert Main Info -->

                        <!-- Alert Source -->

                        <div fxFlex="20">

                            <mat-card class="card-demo mat-card">
                                <mat-card-title class="mat-card-title">
                                    <div class="h3">
                                        Alert Source
                                    </div>

                                </mat-card-title>

                                <mat-card-content class="large mat-card-content" style="margin-top: 20px;">


                                    <div style="margin-top: 20px;">

                                        <div class="h3">
                                            Vendor
                                        </div>

                                        <div class="h3" style="margin-top: 5px;">
                                            {{ alertDetails.vendor?.vendor || 'No Data' }}

                                        </div>
                                    </div>


                                    <div style="margin-top: 20px;">

                                        <div class="h3">
                                            Provider
                                        </div>

                                        <div class="h3" style="margin-top: 5px;">
                                            {{ alertDetails.vendor?.provider || 'No Data' }}

                                        </div>
                                    </div>

                                    <div style="margin-top: 20px;">

                                        <div class="h3">
                                            Version
                                        </div>

                                        <div class="h3" style="margin-top: 5px;">
                                            {{ alertDetails.vendor?.providerVersion || 'No Data' }}

                                        </div>
                                    </div>


                                    <div style="margin-top: 20px;">

                                        <div class="h3">
                                            SubProvider
                                        </div>

                                        <div class="h3" style="margin-top: 5px;">
                                            {{ alertDetails.vendor?.subProvider || 'No Data' }}

                                        </div>
                                    </div>


                                </mat-card-content>

                            </mat-card>

                        </div>

                        <!-- Alert Source -->

                    </div>
                </div>

                <!-- Left Card -->

                <!-- Right Card -->


                <div class="sec3" fxFlex="20" fxFlex.xs="20"
                     style="margin-right:20px; margin-top: 20px; margin-right: 20px;">

                    <mat-card *ngIf="!alertDetails.id_Ticket_ConnectWise" class="card-demo mat-card">
                        <mat-card-title class="mat-card-title">
                            <div class="h3">
                                Log Ticket In ConnectWise
                            </div>

                        </mat-card-title>

                        <mat-card-content class="large mat-card-content">
                            <button (click)="LogTicket(alertDetails)"
                                    aria-label="Log Ticket"
                                    class="mat-focus-indicator add-product-button 
                            fuse-white mt-24 mt-md-0 ng-tns-c271-134 mat-raised-button mat-button-base"
                                    mat-raised-button

                                    style="margin-top: 5px;">
                                Log Ticket
                            </button>
                        </mat-card-content>

                    </mat-card>


                    <mat-card class="card-demo mat-card" style="margin-top: 20px;">
                        <mat-card-title class="mat-card-title">
                            <div class="h3">
                                Manage Alert
                            </div>
                        </mat-card-title>
                        <mat-card-content class="large mat-card-content">

                            <div fxLayout="column">

                                <form [formGroup]="updateAlertForm" fxFlex="1 0 auto" fxLayout="column"
                                      fxLayoutAlign="start" name="form">

                                    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label>Status</mat-label>
                                            <mat-select formControlName="status" required>
                                                <mat-option [value]="'NewAlert'">
                                                    NewAlert
                                                </mat-option>
                                                <mat-option [value]="'InProgress'">
                                                    InProgress
                                                </mat-option>
                                                <mat-option [value]="'Resolved'">
                                                    Resolved
                                                </mat-option>
                                                <mat-option [value]="'Dismissed'">
                                                    Dismissed
                                                </mat-option>

                                            </mat-select>
                                            <mat-error>Status is required!</mat-error>
                                        </mat-form-field>

                                    </div>

                                    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label>Feedback</mat-label>
                                            <mat-select formControlName="feedback" required>
                                                <mat-option [value]="'Unknown'">
                                                    Unknown
                                                </mat-option>
                                                <mat-option [value]="'TruePositive'">
                                                    TruePositive
                                                </mat-option>
                                                <mat-option [value]="'FalsePositive'">
                                                    FalsePositive
                                                </mat-option>
                                                <mat-option [value]="'BenignPositive'">
                                                    BenignPositive
                                                </mat-option>

                                            </mat-select>
                                            <mat-error>Feedback is required!</mat-error>
                                        </mat-form-field>

                                    </div>

                                    <div fxLayout="row" fxLayoutAlign="start start">
                                        <mat-form-field appearance="outline" fxFlex>
                                            <mat-label>Assign To</mat-label>
                                            <input formControlName="assignTo" matInput name="Assign To" required>
                                            <mat-error>Assigned To is required!</mat-error>

                                        </mat-form-field>
                                    </div>


                                    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">


                                        <mat-form-field appearance="outline" fxFlex="100">
                                            <mat-label>Comment</mat-label>
                                            <mat-select formControlName="comment" required>
                                                <mat-option [value]="'Closed In IPC'">
                                                    Closed In IPC
                                                </mat-option>
                                                <mat-option [value]="'Closed In MCAS'">
                                                    Closed In MCAS
                                                </mat-option>


                                            </mat-select>
                                            <mat-error>Comment is required!</mat-error>
                                        </mat-form-field>

                                    </div>


                                </form>
                                <div class="m-0 p-16" fxLayout="column" fxLayoutAlign="start start" mat-dialog-actions>

                                    <button
                                        (click)="updateAlert()"
                                        [disabled]="updateAlertForm.invalid"
                                        aria-label="Update Alert"
                                        class="mat-focus-indicator add-product-button 
                                fuse-white mt-24 mt-md-0 ng-tns-c271-134 mat-raised-button mat-button-base"
                                        mat-raised-button>
                                        SAVE
                                    </button>


                                </div>
                            </div>

                        </mat-card-content>

                    </mat-card>
                </div>


                <!-- Right Card -->


            </div>

        </div>


    </div>

</div>