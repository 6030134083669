import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
// page specific components
import {ComplianceReportComponent} from './compliance-report.component';

import {NgxPowerBiModule} from 'ngx-powerbi';
// components
const components = [
    ComplianceReportComponent
];

@NgModule({
    imports: [
        CommonModule,
        NgxPowerBiModule
    ],
    providers: [],
    declarations: [components],
    exports: components
})

export class ComplianceReportPageModule {
}
