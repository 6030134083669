import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

// page specific components
import {DashboardComponent} from './dashboard.component';

import {NgxPowerBiModule} from 'ngx-powerbi';

// components
const components = [
    DashboardComponent

];

@NgModule({
    imports: [
        CommonModule,
        NgxPowerBiModule
    ],
    providers: [],
    declarations: [components],
    exports: components
})

export class DashboardPageModule {
}
