import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';

import {CommonComponentsModule} from '../../common/common-components.module';

import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTabsModule} from '@angular/material/tabs';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';

import {FuseWidgetModule} from '@fuse/components/widget/widget.module';

import {MatToolbarModule} from '@angular/material/toolbar';

import {ClientDetailsComponent} from "app/components/pages/client-management/client-details/client-details.component";
import {ClientDialog} from "app/components/pages/client-management/client-dialog/client-dialog";
import {ClientUserDialog} from "app/components/pages/client-management/client-user-dialog/client-user-dialog";
import {
    ClientUserDetailsComponent
} from 'app/components/pages/client-management/client-user-details/client-user-details.component';
import {
    ClientSubscriptionsComponent
} from 'app/components/pages/client-management/client-subscriptions/client-subscriptions.component';
import {
    ClientSubscriptionDialog
} from 'app/components/pages/client-management/client-subscription-dialog/client-subscription-dialog'

import {FuseSharedModule} from '@fuse/shared.module';
import { ClientConfirmationDialogComponent } from './client-confirmation-dialog/client-confirmation-dialog.component';

const components = [
    ClientDetailsComponent,
    ClientDialog,
    ClientUserDialog,
    ClientUserDetailsComponent,
    ClientSubscriptionsComponent,
    ClientSubscriptionDialog,
    ClientConfirmationDialogComponent
];

@NgModule({
    imports: [
        CommonModule,
        CommonComponentsModule,
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatExpansionModule,
        MatTabsModule,
        MatPaginatorModule,
        FuseWidgetModule,
        MatTableModule,
        MatSortModule,
        MatFormFieldModule,
        MatDialogModule,
        FormsModule,
        MatInputModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatSelectModule,
        FuseSharedModule

    ],

    declarations: components,
    exports: components
})

export class ClientManagementModule {
}
