<div [ngClass]="fuseConfig.layout.navbar.secondaryBackground" class="navbar-top">

    <div class="logo">
        <img class="logo-icon" src="assets/images/logos/Quorum.png">
        <span class="logo-text  ">Cyber One</span>
    </div>

    <div class="buttons">

        <button (click)="toggleSidebarFolded()" class="toggle-sidebar-folded"
                fxHide.lt-lg mat-icon-button>
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <button (click)="toggleSidebarOpened()" class="toggle-sidebar-opened"
                fxHide.gt-md mat-icon-button>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>

    </div>

</div>


<div [fusePerfectScrollbarOptions]="{suppressScrollX: true}" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
     class="navbar-scroll-container" fusePerfectScrollbar>

    <!-- <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">

        <div class="h3 username">Charlie Adams</div>
        <div class="h5 email hint-text mt-8">adams.charlie@mail.com</div>
        <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
            <img class="avatar" src="assets/images/avatars/Velazquez.jpg">
        </div>

    </div> -->

    <div class="navbar-content" style="padding-top: 0px">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

</div>
