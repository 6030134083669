<mat-toolbar class="quorum-color" matDialogTitle style="background-color: orangered;">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
        <span class="title dialog-title">Save Confirmation</span>
        <button (click)="close(actions.Cancel)" aria-label="Close dialog" mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>

<p class="m-0 p-16">It appears that you have changed the Azure App Registration Secret. Are you sure you want to proceed?</p>

<div class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center" mat-dialog-actions>
    <button 
        (click)="close(actions.Back)" 
        aria-label="Confirm" 
        class="mat-focus-indicator add-product-button fuse-white mt-md-0 ng-tns-c271-134 mat-raised-button mat-button-base" 
        mat-raised-button>
        Back
    </button>
    <button 
        (click)="close(actions.Save)" 
        aria-label="Confirm" 
        class="mat-focus-indicator add-product-button fuse-white mt-md-0 ng-tns-c271-134 mat-raised-button mat-button-base" 
        mat-raised-button>
        Confirm
    </button>    
</div>