import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

// services
import {DashboardService} from '../../../../services/dashboard.service';
import {UserService} from '../../../../services/user.service';

import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';

@Component({
    selector: 'app-azure-module-virtual-report-page',
    templateUrl: './azure-module-virtual-report.component.html',
    styleUrls: ['./azure-module-virtual-report.component.scss']
})

export class AzureVirtualReportComponent implements OnInit {
    show: boolean = false
    error: boolean = false

    public title = 'AzureModule';
    public tokenType = 'Embed';
    public screenHeight: number;

    // public breadcrumbItems: BreadcrumbItem[] =
    //     [
    //         { Title: 'Dashboard', URL: '/dashboard' }
    //     ];

    //For Power Bi Embed
    public accessToken: string;
    public embedUrl: string;
    public id: string;

    constructor(
        private splashScreenService: FuseSplashScreenService,
        private dashboardService: DashboardService,
        private router: Router,
        private userService: UserService
    ) {

    }

    ngOnInit(): void {
        this.screenHeight = (window.innerHeight);

        //  this.getAlertStatistics();
        this.getPowerBIEmbedConfig();

    }

    getPowerBIEmbedConfig(): void {
        this.dashboardService.getPowerBIEmbedConfig('AzureModuleVirtual').subscribe(response => {

                if (response) {
                    this.show = true;
                    this.error = false;

                    this.accessToken = response.accessToken;
                    this.embedUrl = response.embedUrl;
                    this.id = response.id;

                } else {

                    this.show = true;
                    this.error = true;

                    console.log("Error " + response);

                }

            },

            err => {

                console.log("Error");
                console.log("Error " + err);

            }
        );

    }

}