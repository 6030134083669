<!-- FUSE Splash Screen -->
<div *ngIf="!show" id="fuse-splash-screen">
    <div class="center">
        <div class="logo">
            <img src="assets/images/logos/Quorum.png" width="128">
        </div>
        <!-- Material Design Spinner -->
        <div class="spinner-wrapper">
            <div class="spinner">
                <div class="inner">
                    <div class="gap"></div>
                    <div class="left">
                        <div class="half-circle"></div>
                    </div>
                    <div class="right">
                        <div class="half-circle"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- / Material Design Spinner -->
    </div>
</div>
<!-- / FUSE Splash Screen -->

<div *ngIf="show" class="page-layout carded fullwidth inner-scroll" id="client-details">
    <div class="top-bg quorum-color"></div>
    <div class="center">
        <div class="header quorum-color" fxLayout="row" fxLayout.gt-xs="row" fxLayoutAlign="center center"
             fxLayoutAlign.gt-xs="space-between center">
            <div class="logo mb-24 mb-sm-0" fxLayout="column" fxLayoutAlign="start center">
                <span [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}" class="logo-text h1">{{ clientName }} - Manage Users</span>
            </div>
            <div class="logo mb-24 mb-sm-0" fxLayout="column" fxLayoutAlign="end center">
                <button (click)="addClientUser()" class="mat-focus-indicator add-product-button 
            fuse-white mt-24 mt-md-0 ng-tns-c271-134 mat-raised-button mat-button-base" mat-raised-button tabindex="0">
                    <span class="mat-button-wrapper"><span>ADD NEW USER</span></span>
                    <div class="mat-ripple mat-button-ripple" matripple="">
                    </div>
                    <div class="mat-button-focus-overlay"></div>
                </button>
            </div>
        </div>

        <div class="content-card">
            <div class="mat-elevation-z8">
                <table [dataSource]="dataSource" mat-table>

                    <ng-container matColumnDef="id">
                        <th *matHeaderCellDef mat-header-cell style="color: white;">ID</th>
                        <td *matCellDef="let row" mat-cell style="color: white;"> {{ row.id }}</td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th *matHeaderCellDef mat-header-cell style="color: white;"> User Name</th>
                        <td *matCellDef="let row" mat-cell style="color: white;"> {{ row.userName }}</td>
                    </ng-container>

                    <ng-container matColumnDef="role">
                        <th *matHeaderCellDef mat-header-cell style="color: white;"> Role</th>
                        <td *matCellDef="let row" mat-cell style="color: white;"> {{ row.role }}</td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th *matHeaderCellDef mat-header-cell style="color: white;">Action</th>
                        <td *matCellDef="let row" mat-cell style="color: white;">
                            <button disabled mat-icon-button>
                                <mat-icon (click)="openDialog(row)" style="color: white;">edit</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>

                    <!-- Row shown when there is no matching data. -->
                    <tr *matNoDataRow class="mat-row">
                        <td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>

