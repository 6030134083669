import {Component, Input, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import { ClientConfirmationDialogActions } from 'app/models/response/client-detail.model';

@Component({
    selector: 'app-client-confirmation-dialog',
    templateUrl: './client-confirmation-dialog.component.html',
    styleUrls: ['./client-confirmation-dialog.component.scss']
})
export class ClientConfirmationDialogComponent implements OnInit {
    actions = ClientConfirmationDialogActions;

    constructor(public dialogRef: MatDialogRef<ClientConfirmationDialogComponent>) {}

    ngOnInit(): void {}

    close(action: ClientConfirmationDialogActions): void {
        this.dialogRef.close({action: action});
    }
}
