import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {
    ClientDetail,
    ClientDialogActions
} from '../../../../models/response/client-detail.model';

interface ToggleFlag {
    value: string;
}

@Component({
    selector: 'client-dialog',
    templateUrl: 'client-dialog.html',
    styleUrls: ['client-dialog.scss']
})
export class ClientDialog {
    action: string;
    clientForm: FormGroup;
    client: ClientDetail;
    dialogTitle: string;
    dialogActions = ClientDialogActions;
    originalAppRegSecret: string;
    toggleFlags: ToggleFlag[] = [{value: 'Enabled'}, {value: 'Disabled'}];

    constructor(
        public dialogRef: MatDialogRef<ClientDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder
    ) {
        this.action = data.action;
        this.originalAppRegSecret = data.originalAppRegSecret;

        if (this.action == 'new') {
            this.dialogTitle = 'New Client';
        }

        if (this.action === 'edit') {
            this.dialogTitle = 'Edit Client';
            this.client = data.data;
        } else {
            this.dialogTitle = 'New Client';
            this.client = new ClientDetail();
        }

        this.clientForm = this.createClientForm();
    }

    createClientForm(): FormGroup {
        return this._formBuilder.group({
            name: [this.client.name],
            tenantID: [this.client.tenantID],
            appRegClientID: [this.client.appRegClientID],
            appRegSecret: [this.client.appRegSecret],
            companyIdentifier_ConnectWise: [
                this.client.companyIdentifier_ConnectWise
            ],
            autoLogConnectWiseTickets: [this.client.autoLogConnectWiseTickets],
            syncSecurityAndCompliance: [this.client.syncSecurityAndCompliance],
            securityCompliance_DomainName: [
                this.client.securityCompliance_DomainName
            ]
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    close(dialogAction: ClientDialogActions): void {
        if (
            dialogAction === ClientDialogActions.Save &&
            this.clientForm.get('appRegSecret').value !==
                this.originalAppRegSecret
        ) {
            dialogAction = ClientDialogActions.ConfirmationBeforeSave;
        }

        // remove the appRegSecret from the form data everytime the form is saved
        if (dialogAction === ClientDialogActions.Save) {
            this.clientForm.removeControl('appRegSecret');
        }

        this.dialogRef.close({
            action: dialogAction,
            clientForm: this.clientForm
        });
    }
}
